import React from "react";
import PricingHeroArea from "./PricingHeroArea";
import PricingPageArea from "./PricingPageArea";
import PricingFaqsArea from "./PricingFaqsArea";

const Pricing = () => {
  return (
    <>
      <PricingHeroArea />
      <PricingPageArea />
      <PricingFaqsArea />
    </>
  );
};

export default Pricing;

import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AboutManagerCallDo = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Wrapper>
      <div className="call-do-action-area inner" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="row align-items-center call-do-action-bg">
            <div className="col-lg-6 col-md-12">
              {/* <div className="call-do-action-video">
              <a
                className="video-vemo-icon venobox vbox-item"
                data-vbtype="youtube"
                data-autoplay="true"
                href="https://www.youtube.com/watch?v=e6R6VsgD8yQ&amp;t=179s"
              >
                <i className="bi bi-play"></i>
              </a>
            </div> */}

              <div className="call-do-action-video">
                {!isPlaying ? (
                  <Link
                    className="video-vemo-icon venobox vbox-item"
                    data-vbtype="video"
                    data-autoplay="true"
                    target="_blank"
                    href="/assets/video/md. siddiqul alam.mp4"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPlaying(true);
                    }}
                  >
                    <i className="bi bi-play"></i>
                  </Link>
                ) : (
                  <div className="video-modal">
                    <video width="600" controls autoPlay>
                      <source
                        src="/assets/video/md. siddiqul alam.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <button
                      className="close-btn"
                      onClick={() => setIsPlaying(false)}
                    >
                      ✖
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 matha-ta-ghurlo">
              <div className="section-title two">
                {/* <h4>Manager</h4> */}
                {/* <h1>The Best Eque Heritage Hotel & Resort</h1>
                <h1 className="fs-3">Saidpur, Nilphamari</h1> */}
                <p className="section-desc-2 mb-0">Assalamu alaikum,</p>
                <p className="section-desc-2 mt-0" style={{ textAlign: "justify" }}>
                  Your well-being and comfort are our top concerns. Our
                  passionate team is focused on creating a secure and truly
                  memorable experience for you, filled with the warmth of our
                  hospitality. We're thrilled to welcome you into our family!
                </p>
              </div>

              {/* <div
                className="call-do-action-text"
                style={{ textAlign: "justify" }}
              >
                <Link to="#">
                  “ Model. Appropriately create interactive infrastructures
                  after main Holisticly facilitate stand-alone inframe of the
                  world ”
                </Link>
              </div> */}

              <div className="call-do-action-author">
                <div className="call-do-action-author-thumb">
                  <img
                    src="/assets/images/home-1/md. siddiqul alam.jpg"
                    alt=""
                    style={{ width: "90px", height: "90px" }}
                  />
                </div>
                <div className="call-do-action-content">
                  <h4>Md. Siddiqul Alam</h4>
                  <p className="mb-0">Managing Director Eque Group</p>
                  <p>Eque Group</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }

  .video-modal video {
    width: 600px;
    max-width: 100%;
    height: 600px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: none;
    cursor: pointer;
  }

  @media screen and (max-width: 425px) {
    .video-modal video {
      /* width: 600px;
    max-width: 100%; */
      height: 360px !important;
    }
  }

  @media screen and (max-width: 375px) {
    .video-modal video {
      height: 300px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .video-modal video {
      height: 250px !important;
    }
  }
`;

export default AboutManagerCallDo;

import React from "react";
import FindRoomHeroSection from "./FindRoomHeroSection";
import FindRoomPageArea from "./FindRoomPageArea";
import FindRoomContactArea from "./FindRoomContactArea";

const FndRoom = () => {
  return (
    <>
      <FindRoomHeroSection />
      <FindRoomPageArea />
      <FindRoomContactArea />
    </>
  );
};

export default FndRoom;

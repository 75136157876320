import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import serviceDataArray from "../../serviceData.js";

const ServicesArea = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(serviceDataArray);
  }, []);

  const fourServices = services.slice(0, 4);

  return (
    <Wrapper>
      <div className="facilities-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title two">
                <h4>Resort's Services</h4>
                <h1>Enjoy Complete & Best</h1>
                <h1>Quality Services</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="luxury-button">
                <Link to="/service">View More services</Link>
              </div>
            </div>
          </div>

          {fourServices &&
            fourServices.map((service, index) => {
              return (
                <div
                  key={service.id}
                  className={`row add-boder d-flex ${
                    index % 2 === 0 ? "" : "flex-row-reverse"
                  }`}
                >
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="single-facilities-images-box">
                      <div className="facilities-thumb">
                        <img
                          src={service.image}
                          alt=""
                          // style={{ width: "630px", height: "370px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div
                      className={`single-facilities-box ${
                        index % 2 === 0 ? "" : "two"
                      } `}
                    >
                      <div className="facilities-content">
                        {/* <h4>{service.subtitle}</h4> */}
                        <h1>{service.title}</h1>
                        <p style={{ textAlign: "justify" }}>
                          {service.description}
                        </p>
                        <Link
                          className="facilities-button"
                          to={`/services-details/${service.id}`}
                        >
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                      <div
                        className={`facilities-number ${
                          index % 2 === 0 ? "" : "two"
                        }`}
                      >
                        <h1>{service.id}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  @media screen and (max-width: 768px) {
    .flexxx {
      flex-direction: column !important;
    }
  }
`;

export default ServicesArea;

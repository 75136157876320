import React from "react";
import BlogHeroArea from "./BlogHeroArea";
import BlogPageArea from "./BlogPageArea";

const Blog = () => {
  return (
    <>
      <BlogHeroArea />
      <BlogPageArea/>
    </>
  );
};

export default Blog;

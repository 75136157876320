const features = [
  {
    id: 1,
    icon: "/assets/images/feature-area/rent-car.png",
    title: "Rent a car",
    description:
      "Experience the freedom to explore with our reliable rental cars, perfect for any guest looking to discover the area at their own pace.",
  },
  {
    id: 2,
    // icon: "/assets/images/home-1/feature-2.png",
    icon: "/assets/images/feature-area/business-center.png",
    title: "Business Center",
    description:
      "Business Center	A fully equipped workspace with fast internet and private meeting rooms. This allows guests to easily mix work and relaxation in a peaceful setting.",
  },
  {
    id: 3,
    icon: "/assets/images/feature-area/ticket-airline.png",
    title: "Air ticket",
    description:
      "Air ticket booking services for guests, ensuring a seamless travel experience with flexible payment options.",
  },
  {
    id: 4,
    icon: "/assets/images/feature-area/laundry-services.png",
    title: "Laundry Service",
    description:
      "Our personalized laundry service offers convenient and prompt pick-up, expert washing and folding, and secure drop-off at your desired time, all at a competitive rate tailored to fit your specific needs.",
  },
  {
    id: 5,
    icon: "/assets/images/feature-area/conference.png",
    title: "Conference Hall",
    description:
      "The conference hall, with its stunning pillar less design, is available for rent, making it the perfect venue for hosting your guests in a spacious and elegant setting.",
  },
  {
    id: 6,
    icon: "/assets/images/feature-area/tour-services.png",
    title: "Tours Service",
    description:
      "We have personalized tour services for our guests, ensuring an unforgettable experience tailored to your interests and preferences.",
  },
];
export default features;

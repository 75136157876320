import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import roomsArray from "../../roomData.js";

const RoomArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      1200: { items: 3 },
    },
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <li key={`full-${i}`}>
          <i className="bi bi-star-fill"></i>
        </li>
      );
    }
    if (halfStar) {
      stars.push(
        <li key="half">
          <i className="bi bi-star-half"></i>
        </li>
      );
    }
    return stars;
  };

  return (
    <Wrapper>
      <div className="room-area">
        <div className="container">
          <div className="row margin-top">
            {rooms.length > 0 && (
              <OwlCarousel className="room_list" {...options}>
                {rooms &&
                  rooms.map((room) => (
                    <div key={room.id} className="room-single-box">
                      <div className="room-thumb">
                        <img src={room.img} alt={room.title} />
                        <div className="room-details-button">
                          <Link to={`/room-details/${room.id}`}>
                            View Details <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="room-pricing">
                        <span className="dolar text-white">{room.price}</span>
                        <span>{room.dayType}</span>
                      </div>

                      <div className="room-content">
                        <h4>{room.title}</h4>
                        <Link to={`/room-details/${room.id}`}>
                          {room.subtitle}
                        </Link>
                        <div className="d-flex justify-content-between align-items-center">
                          {room &&
                          !room.subtitle.includes("Hall") &&
                          !room.subtitle.includes("Cabin") ? (
                            <p>Check in - {room.checkIn}</p>
                          ) : (
                            <p>No Check in </p>
                          )}

                          {room &&
                          !room.subtitle.includes("Hall") &&
                          !room.subtitle.includes("Cabin") ? (
                            <p>No Check out - {room.checkOut}</p>
                          ) : (
                            <p>No Check out</p>
                          )}
                        </div>
                      </div>
                      <div className="room-bottom">
                        <div className="room-bottom-icon">
                          <span>
                            <img
                              src="/assets/images/home-1/room-bottom-icon.png"
                              alt={room.details}
                            />
                            {room.details}
                          </span>
                        </div>
                        <div className="coustomar-rating">
                          <ul>{renderStars(room.rating)}</ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* .owl-carousel .owl-item img {
    width: auto !important;
  } */
  .room-bottom-icon {
    width: auto; /* Set width to auto */
  }
  .room-bottom-icon img {
    width: 20px; /* Adjust size of the image */
    height: auto; /* Maintain aspect ratio */
  }
  .owl-nav {
    display: none !important;
  }
  .room-area .owl-dots {
    display: none !important;
  }
`;

export default RoomArea;

import React from "react";
import GalleryHeroArea from "./GalleryHeroArea";
import GalleryPageArea from "./GalleryPageArea";

const Gallery = () => {
  return (
    <>
      <GalleryHeroArea />
      <GalleryPageArea />
    </>
  );
};

export default Gallery;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import brandAreaArray from "../../src/brandAreaData.js";

const BrandArea = () => {
  const [brandAreas, setBrandAreas] = useState([]);

  useEffect(() => {
    setBrandAreas(brandAreaArray);
  }, []);

  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 2 },
      768: { items: 2 },
      1200: { items: 3 },
    },
  };

  return (
    <Wrapper>
      <div className="brand-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                {brandAreas.length > 0 && (
                  <OwlCarousel className="brand-list owl-carousel" {...options}>
                    {brandAreas &&
                      brandAreas.map((item) => {
                        return (
                          <div key={item.id} className="col-lg-12">
                            <div className="single-brand-box">
                              <div className="brand-thumb">
                                {/* <img
                                  height={35}
                                  src={item.img}
                                  alt="Brand img"
                                /> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .owl-carousel .owl-item img {
    width: auto;
  }
  .owl-nav button.owl-prev,
  .owl-next {
    display: none !important;
  }
`;

export default BrandArea;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import blogArray from "../../blogData.js";

const BlogArea = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogArray);
  }, []);
  const threeBlog = blogs.slice(0, 3);

  return (
    <div className="blog-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {/* <div
            className="col-md-12 text-center"
            data-cue="zoomIn"
            data-show="true"
            style={{
              animationName: "zoomIn",
              animationDuration: "2500ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          > */}
            <div className="section-title center">
              <div className="section-thumb">
                <img src="/assets/images/home-1/section-shape111.png" alt="" />
              </div>
              <h1>Latest blog</h1>
              <p className="section-desc-1">
                Proactively morph optimal infomediaries rather than accurate
                expertise. Intrinsicly progressive resources rather than
                resource-leveling
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          {threeBlog &&
            threeBlog.map((blog) => {
              return (
                <div key={blog.id} className="col-lg-4 col-md-6">
                  <div className="single-blog-box">
                    <div className="single-blog-thumb">
                      <img src={blog.image} alt="" />
                    </div>
                    <div className="blog-content">
                      <div className="meta-blog">
                        <span>{blog.date}</span>
                        <span>{blog.category}</span>
                      </div>
                      <Link to={`/blog-details/${blog.id}`}>{blog.title.slice(0, 50)}...</Link>
                    </div>
                    <div className="blog-button">
                      <Link to={`/blog-details/${blog.id}`}>
                        Read More
                        <span>
                          <i className="bi bi-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BlogArea;

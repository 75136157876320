import React, { useEffect, useState } from "react";
import styled from "styled-components";
import foodItems from "../../CafeData.js";

const CafeFoodMenuArea = () => {
  const [cafeFoods, setCafeFoods] = useState([]);
  const [activeTab, setActiveTab] = useState("All");

  useEffect(() => {
    setCafeFoods(foodItems);
  }, []);

  const uniqueCategories = Array.from(
    new Set(foodItems.map((item) => item.category))
  );

  const tabs = ["All", ...uniqueCategories];

  // Handler to update active tab
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Filter the food items based on the active tab
  const filteredFoods =
    activeTab === "All"
      ? cafeFoods
      : cafeFoods.filter(
          (item) => item.category.toLowerCase() === activeTab.toLowerCase()
        );

  return (
    <Wrapper>
      <div className="food-menu-area py-2 mt-5 rounded">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img src="/assets/images/home-1/section-shape111.png" alt="" />
                </div>
                <h1>Cafe Food Menu</h1>
              </div>
            </div>
          </div>

          {/* Tab Menu */}
          <div className="row">
            <div className="col-md-12">
              <div className="food-tab-item">
                <div className="food-tab-menu d-flex justify-content-center align-items-center">
                  <ul
                    className="nav nav-pills mb-3 d-flex justify-content-left align-items-center gap-2 uull row_horizon"
                    role="tablist"
                  >
                    {tabs.map((tab) => (
                      <li key={tab} className="nav-item" role="presentation">
                        <button
                          type="button"
                          className={`nav-link ${
                            activeTab === tab ? "active" : ""
                          }`}
                          onClick={() => handleTabChange(tab)}
                          style={{
                            textTransform: "uppercase",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {tab}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Tab Content */}
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <div className="row">
                      {filteredFoods.map((item) => (
                        <div key={item.id} className="col-lg-6 col-md-12">
                          <div className="single-food-item-box">
                            <div className="food-thumb">
                              <img
                                src={item.img}
                                alt={item.name}
                                style={{ width: "140px", height: "140px" }}
                              />
                            </div>
                            <div className="food-item-content">
                              <h4>{item.name}</h4>
                              <span>{item.price}</span>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                      {filteredFoods.length === 0 && (
                        <p>No items available for this category.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* .facilities-area {
    padding: 109px 0 87px;
  } */

  .food-menu-area {
    /* padding: 122px 0 102px; */
    padding: 0px 0 102px !important;
  }

  .row_horizon {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    -webkit-overflow-scrolling: touch;
  }

  .uull {
    padding-left: 0 !important;
  }
  .uull li {
    margin-bottom: 7px;
  }

  .row_horizon::-webkit-scrollbar {
    height: 5px;
  }

  .row_horizon::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .row_horizon::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .row_horizon::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default CafeFoodMenuArea;

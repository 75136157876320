import React from "react";

const MapArea = () => {
  return (
    <div className="google-map">
      <div className="row">
        <div className="col-md-12">
          <div className="google-map-content">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14371.661377762564!2d88.9021511!3d25.7733596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e3517fbcb84f95%3A0xaf6c721b53293947!2sEque%20Heritage%20Hotel%20and%20Resort%2C%20Saidpur%2C%20Nilphamari.!5e0!3m2!1sen!2sbd!4v1732451828928!5m2!1sen!2sbd"
              title="Dhaka, Bangladesh"
              width="100%"
              height="480"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapArea;

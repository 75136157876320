const foodItems = [
  {
    id: 1,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/Appetizer/Special Fried Wonthon.jpg",
    name: "Special Fried Wonthon (8 Pcs)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 2,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Fried Wonthon (8 Pcs)",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 3,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Fried Chicken Wings (8 Pcs)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 4,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Fried Prawn (8 Pcs)",
    price: "৳310",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 5,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/Appetizer/French Fry.jpg",
    name: "French Fry",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 6,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/Appetizer/Spring Roll.jpg",
    name: "Spring Roll (8 pcs)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 7,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Fish Finger (8 Pcs)",
    price: "৳320",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 8,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai Fried Chicken (8 Pcs)",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 9,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai Fried Chicken (4 Pcs)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 10,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/Appetizer/Fried Chicken.jpg",
    name: "Fried Chicken (4 Pcs)",
    price: "৳270",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 11,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Fried Chicken (8 Pcs)",
    price: "৳520",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 12,
    category: "Appetizer",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Vegetable Pakora (8 Pcs)",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Soup
  {
    id: 13,
    category: "Soup",
    img: "/assets/images/restaurant menu/Soup/Eque Special Thai Soup.jpg",
    name: "Eque Special Thai Soup",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 14,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai Soup with Chicken",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 15,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai Clear Soup",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 16,
    category: "Soup",
    img: "/assets/images/restaurant menu/Soup/Hot & Sour Soup.jpg",
    name: "Hot & Sour Soup",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 17,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Onion Thai Soup",
    price: "৳380",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 18,
    category: "Soup",
    img: "/assets/images/restaurant menu/Soup/Chicken Corn Soup.jpg",
    name: "Chicken Corn Soup",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 19,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Vegetable Soup",
    price: "৳320",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 20,
    category: "Soup",
    img: "/assets/images/restaurant menu/Soup/Clear Vegetable Soup.jpg",
    name: "Clear Vegetable Soup",
    price: "৳330",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 21,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Special Corn Soup",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 22,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai SZe-chuan Soup",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 23,
    category: "Soup",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Wonthon Noodles Soup",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Salad
  {
    id: 24,
    category: "Salad",
    img: "/assets/images/restaurant menu/Salad/Cabbage Salad.jpg",
    name: "Cabbage Salad",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 25,
    category: "Salad",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Eque Special Salad",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 26,
    category: "Salad",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Green Salad",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 27,
    category: "Salad",
    img: "/assets/images/restaurant menu/Salad/Fruits Salad.jpg",
    name: "Fruits Salad",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 28,
    category: "Salad",
    img: "/assets/images/restaurant menu/Salad/ChickenPrawn Cash Nut Salad.jpg",
    name: "Chicken/Prawn cash-Nut-Salad",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 29,
    category: "Salad",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Green Mix Salad",
    price: "৳110",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Rice Dishes-chiness Style
  {
    id: 30,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/Rice Dishes-chinese Style/Chicken Fried Rice.jpg",
    name: "Chicken Fried Rice",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 31,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/Rice Dishes-chinese Style/Egg Fried Rice.jpg",
    name: "Egg Fried Rice",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 32,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/Rice Dishes-chinese Style/Vegetable Fried Rice.jpg",
    name: "Vegetable Fried Rice",
    price: "৳230",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 33,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Mixed Fried Rice",
    price: "৳320",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 34,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/Rice Dishes-chinese Style/Masala Fried Rice.jpg",
    name: "Masala Fried Rice",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 35,
    category: "Rice Dishes-chiness Style",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Eque Spe. Thai Fried Rice",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Noodles & Pasta
  {
    id: 36,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Thai Noodles Chai/Prawn",
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 37,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Noodles Chi/Pra/B Gravy",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 38,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Mixed Noodles",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 39,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/Noodles & Pasta/Eque Spacial Thai Noodles.jpg",
    name: "Eque Special Thai Noodles",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 40,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Pasta Chi/Pra/Beef",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 41,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Vagetable Pasta",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 42,
    category: "Noodles & Pasta",
    img: "/assets/images/restaurant menu/Noodles & Pasta/Eque Special Pasta.jpg",
    name: "Eque Special Pasta",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Chowmien & Chopsuey
  {
    id: 43,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chowmien with Chicken",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 44,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Vegetable Chowmien",
    price: "৳320",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 45,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/Chowmien & Chopsuey/Eque Special Mix Chowmien.jpg",
    name: "Eque Special Mix Chowmien",
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 46,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chopsuey with Chicken",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 47,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Vegetable Chapsuey",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 48,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/Chowmien & Chopsuey/American Chopsuey.jpg",
    name: "American Chapsuey",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 49,
    category: "Chowmien & Chopsuey",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Eque Special Chopsuey",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Lunch & Dinner
  {
    id: 50,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/Lunch & Dinner/Steam Rice.jpg",
    name: "Steam Rice",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 51,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Plain Pollaw",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 52,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/Lunch & Dinner/Chicken Biriani.jpg",
    name: "Chicken Biriani (F)",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 53,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/Lunch & Dinner/Chicken Biriani.jpg",
    name: "Chicken Biriani (H)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 54,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "HyDrabadi B (F)",
    price: "৳560",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 55,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "HyDrabadi B (H)",
    price: "৳290",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 56,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Khichuri (H)",
    price: "৳230",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 57,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Beef Khichuri (H)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 58,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/Lunch & Dinner/Beef Biriani.jpg",
    name: "Beef Biriani (H)",
    price: "৳260",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 59,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Mutton Biriani (H)",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 60,
    category: "Lunch & Dinner",
    img: "/assets/images/restaurant menu/Lunch & Dinner/Kacchi Biryani.jpg",
    name: "Kacchi Biryani Minimam 15 Order",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Bangla Item
  {
    id: 61,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Small Fish Curry.jpg",
    name: "Small Fish Curry",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 62,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Hilsha Fish Fry.jpg",
    name: "Hilsha Fish Fry",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 63,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Hilsha Fish vhuna.jpg",
    name: "Hilsha Fish vhuna",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 64,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Sorisha Hilsha.jpg",
    name: "Sorisha Hilsha",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 65,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Ruhi Fish Curry.jpg",
    name: "Ruhi Fish Curry",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 66,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Ruhi Fish Fry.jpg",
    name: "Ruhi Fish Fry",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 67,
    category: "Bangla Item",
    img: "/assets/images/restaurant menu/Bangla Item/Vorta Egg Tomato Potato.jpg",
    name: "Vorta (Egg/Tomato/Potato)",
    price: "৳50",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Beef
  {
    id: 68,
    category: "Beef",
    img: "/assets/images/restaurant menu/beef/Beef Masala.jpg",
    name: "Beef Masala",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 69,
    category: "Beef",
    img: "/assets/images/restaurant menu/beef/Beef Chili Onion.jpg",
    name: "Beef Chili Onion",
    price: "৳480",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 70,
    category: "Beef",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Beef Chili Onion Dry",
    price: "৳490",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 71,
    category: "Beef",
    img: "/assets/images/restaurant menu/beef/Beef Sizzling.jpg",
    name: "Beef Sizzling",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 72,
    category: "Beef",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Beef With mushroom & Oyester Sauce",
    price: "৳540",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 73,
    category: "Beef",
    img: "/assets/images/restaurant menu/beef/Beef Bhuna.jpg",
    name: "Beef Bhuna",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 74,
    category: "Beef",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Beef Achari",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 75,
    category: "Beef",
    img: "/assets/images/restaurant menu/beef/Beef Jhal Fry.jpg",
    name: "Beef Jhal Fry",
    price: "৳480",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Mutton
  {
    id: 76,
    category: "Mutton",
    img: "/assets/images/restaurant menu/Mutton/Mutton Masala.jpg",
    name: "Mutton Masala",
    price: "৳600",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 77,
    category: "Mutton",
    img: "/assets/images/restaurant menu/Mutton/Mutton Curry.jpg",
    name: "Mutton Curry",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 78,
    category: "Mutton",
    img: "/assets/images/restaurant menu/Mutton/Mutton Bhuna.jpg",
    name: "Mutton Bhuna",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 79,
    category: "Mutton",
    img: "/assets/images/restaurant menu/Mutton/Mutton-do-Payaja.jpg",
    name: "Mutton-do-Payaja",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 80,
    category: "Mutton",
    img: "/assets/images/restaurant menu/Mutton/Mutton Achari.jpg",
    name: "Mutton Achari",
    price: "৳530",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Chicken
  {
    id: 81,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Masala Chicken.jpg",
    name: "Masala Chicken",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 82,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Chicken Chili onion.jpg",
    name: "Chicken Chili onion",
    price: "৳380",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 83,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Chili Dry",
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 84,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Chicken Sizzling.jpg",
    name: "Chicken Sizzling",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 85,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken, Mushroom & Oyester Sauce",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 86,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken-do-payaja",
    price: "৳430",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 87,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Achari",
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 88,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Chicken Curry.jpg",
    name: "Chicken Curry",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 89,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Sweet & Sour Chicken.jpg",
    name: "Sweet & Sour Chicken",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 90,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Chicken jhal fry.jpg",
    name: "Chicken jhal fry",
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 91,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Butter Masala",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 92,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Chicken Tikka Masala",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 93,
    category: "Chicken",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Resmi Chicken Butter Masala",
    price: "৳470",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 94,
    category: "Chicken",
    img: "/assets/images/restaurant menu/Chicken/Chicken Roast.jpg",
    name: "Chicken Roast(1 pcs)",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Fish Item
  {
    id: 95,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/King Prawn Masala.jpg",
    name: "King Prawn Masala (8 pcs)",
    price: "৳680",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 96,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Chili Onion.jpg",
    name: "Prawn Chili Onion",
    price: "৳470",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 97,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Chili Dry.jpg",
    name: "Prawn Chili Dry",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 98,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Hot Sauce.jpg",
    name: "Prawn Hot Sauce",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 99,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Sweet & Sour Prawn.jpg",
    name: "Sweet & Sour Prawn",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 100,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn-do-payaja.jpg",
    name: "Prawn-do-payaja (8 pcs)",
    price: "৳650",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 101,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Masala.jpg",
    name: "Prawn Masala",
    price: "৳600",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 102,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Tandoori.jpg",
    name: "Prawn Tandoori (8 pcs)",
    price: "৳700",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 103,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Prawn, Mushroom & Oyester Sauce",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 104,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Pomfret Rupchada Masala.jpg",
    name: "Pomfret/Rupchada Masala",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 105,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Steam Grill Red Snaper Fish Coral.jpg",
    name: "Steam/Grill Red Snapper Fish/Coral",
    price: "৳1500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 106,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Steam Pomfret.jpg",
    name: "Steam Pomfret",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 107,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Pomfret fry.jpg",
    name: "Pomfret fry",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 108,
    category: "Fish Item",
    img: "/assets/images/restaurant menu/Fish Item/Prawn Sizzling.jpg",
    name: "Prawn Sizzling",
    price: "৳520",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Vegetable
  {
    id: 109,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/Vegetable/Mixed Vegetables.jpg",
    name: "Mixed Vegetables",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 110,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/Vegetable/Thai Vegetables..jpg",
    name: "Thai Vegetables",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 111,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/vegetable/Chicken Vegetables.jpg",
    name: "Chicken Vegetables",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 112,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/Vegetable/Vegetable-do-payaja.jpg",
    name: "Vegetable-do-payaja",
    price: "৳170",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 113,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/Vegetable/Vegetable Curry.jpg",
    name: "Vegetable Curry",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 114,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/Vegetable/Vegetable jhal fry.jpg",
    name: "Vegetable Jhal fry",
    price: "৳160",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 115,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Mixed Vegetable with Mushroom",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 116,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Ponir Vegetable",
    price: "৳370",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 117,
    category: "Vegetable",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Ponir Korai",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Dall
  {
    id: 118,
    category: "Dall",
    img: "/assets/images/restaurant menu/dall/Dall Butter Fry.jpg",
    name: "Dall Butter Fry",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 119,
    category: "Dall",
    img: "/assets/images/restaurant menu/dall/Plain Dall.jpg",
    name: "Plain Dall",
    price: "৳50",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Naan
  {
    id: 120,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Plain Naan.jpg",
    name: "plain Naan",
    price: "৳40",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 121,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Garlic Naan.jpg",
    name: "Garlic Naan",
    price: "৳70",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 122,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Butter Naan.jpg",
    name: "Butter Naan",
    price: "৳60",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 123,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Ponir Naan.jpg",
    name: "Ponir Naan",
    price: "৳90",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 124,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Pudina Naan.jpg",
    name: "Pudina Naan",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 125,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Kashmiri Naan.jpg",
    name: "Kashmiri Naan",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 126,
    category: "Naan",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Eque Special Naan",
    price: "৳170",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 127,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Paratha.jpg",
    name: "Paratha",
    price: "৳40",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 128,
    category: "Naan",
    img: "/assets/images/restaurant menu/Naan/Chapati.jpg",
    name: "Chapati",
    price: "৳30",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Kabab (Bangla Style)
  {
    id: 129,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Chicken Tikka.jpg",
    name: "Chicken Tikka (H)",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 130,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Chicken Tikka.jpg",
    name: "Chicken Tikka (F)",
    price: "৳520",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 131,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Grill Chicken.jpg",
    name: "Grill Chicken (H)",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 132,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Grill Chicken.jpg",
    name: "Grill Chicken (F)",
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 133,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Mutton Boti Kabab.jpg",
    name: "Mutton Boti Kabab",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 134,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Beef Boti Kabab.jpg",
    name: "Beef Boti Kabab",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 135,
    category: "Kabab (Bangla Style)",
    img: "/assets/images/restaurant menu/Kabab (Bangla Style)/Beef Shik.jpg",
    name: "Beef Shik",
    price: "৳280",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Kabab (Indian Style)
  {
    id: 136,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/ChickenTikka (8 pcs).jpg",
    name: "Chicken Tikka (8 pcs)",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 137,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/Chicken Tandury.jpg",
    name: "Chicken Tandury (8 pcs)",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 138,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/Chicken Reshmi (8 pcs).jpg",
    name: "Chicken Resmi (8 pcs)",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 139,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/Kashmir Tandoori Chicken.jpg",
    name: "kashmir Tandoori Chicken",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 140,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/Prawn Tandoori.jpg",
    name: "Prawn Tandoori (8 pcs)",
    price: "৳650",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 141,
    category: "Kabab (Indian Style)",
    img: "/assets/images/restaurant menu/Kabab (Indian Style)/Grill Pomfret.jpg",
    name: "Grill Pomfret",
    price: "৳600",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Dessert
  {
    id: 142,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Bar Ice Cream.jpg",
    name: "Bar Ice Cream",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 143,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Cup Ice Cream.jpg",
    name: "Cup Ice Cream",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 144,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Ice Cream (Choco Vanilla Strawberry).jpg",
    name: "Ice Cream (Choco/Vanilla/Strawberry)",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 145,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Sweet Curd.jpg",
    name: "Sweet Curd",
    price: "৳60",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 146,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Faluda.jpg",
    name: "Faluda",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 147,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Milk Shake.jpg",
    name: "Milk Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 148,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Sweet Firne.jpg",
    name: "Sweet Firne",
    price: "৳70",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 149,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Borhani.jpg",
    name: "Borhani",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 150,
    category: "Dessert",
    img: "/assets/images/restaurant menu/Dessert/Con Ice Cream.jpg",
    name: "Con Ice Cream",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Drinks Item
  {
    id: 151,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/Mocktail Glass (Three Layer).jpg",
    name: "Mocktail Glass (Three Layer)",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 152,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/Soft Drinks Can.jpg",
    name: "Soft Drinks Can",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 153,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Diet Can",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 154,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/Coffee Latte.jpg",
    name: "Coffee Latte",
    price: "৳70",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 155,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/Black Coffee.jpg",
    name: "Black Coffee",
    price: "৳60",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 156,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Ice Coffee",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 157,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Cappuccino Coffee",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 158,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Espresso Coffee",
    price: "৳160",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 159,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Soft Drinks Glass",
    price: "৳50",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 160,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/water.jpg",
    name: "Water Large",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 161,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/Drinks Item/water.jpg",
    name: "Water Small",
    price: "As per body",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 162,
    category: "Drinks Item",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Tea",
    price: "৳30",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Juice
  {
    id: 163,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Mango.jpg",
    name: "Fresh Mango",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 164,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Orange.jpg",
    name: "Fresh Orange",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 165,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Apple.jpg",
    name: "Fresh Apple",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 166,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Papaya.jpg",
    name: "Fresh Papaya",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 167,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Pineapple.jpg",
    name: "Fresh Pineapple",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 168,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Lemon.jpg",
    name: "Fresh Lemon",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 169,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Fresh Lemonet.jpg",
    name: "Fresh Lemonet",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 170,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Mango Lassi.jpg",
    name: "Mango Lassi",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 171,
    category: "Juice",
    img: "/assets/images/restaurant menu/Juice/Sweet Lassi Salt Lassi.jpg",
    name: "Sweet Lassi/Salt Lassi",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Special Set Menu
  {
    id: 172,
    category: "Special Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Set-A:",
    setmenu1: "1. Fried Rice",
    setmenu2: "2. Fried Chicken (1Pc)",
    setmenu3: "3. Mixed Vegetable Seasonal",
    setmenu4: "4. Mineral Water (500ml)",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 173,
    category: "Special Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Set-B:",
    setmenu1: "1. Fried Rice",
    setmenu2: "2. Fried Chicken (1Pc)",
    setmenu3: "3. Mixed Vegetable Seasonal",
    setmenu4: "4. Chicken Masala / Beef Masala",
    setmenu5: "5. One Glass Soft Drinks",
    setmenu6: "6. Mineral Water (500ml)",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 174,
    category: "Special Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Set-C:",
    setmenu1: "1. Plain Polow",
    setmenu2: "2. Vegetable",
    setmenu3: "3. Chicken Curry/Beef Curry",
    setmenu4: "4. Green Salad",
    setmenu5: "5. Mineral Water (500ml)",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 175,
    category: "Special Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Set-D:",
    setmenu1: "1. Hydrabadi Biriani",
    setmenu2: "2. Mixed Salad",
    setmenu3: "3. Soft Drinks",
    setmenu4: "4. Green Salad",
    setmenu5: "5. Water (500ml)",
    price: "৳370",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 176,
    category: "Special Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "Set-E:",
    setmenu1: "1. Fried Rice",
    setmenu2: "2. Chicken Chili onion",
    setmenu3: "3. Mixed Vegetable",
    setmenu4: "4. Fried Chicken",
    setmenu5: "5. Water (500ml)",
    price: "৳360",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Bangla Set Menu
  {
    id: 177,
    category: "Bangla Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "01.",
    setmenu1: "* Plain Rice",
    setmenu2: "* Fried Fish",
    setmenu3: "* Mashed Potatoes",
    setmenu4: "* Dal",
    setmenu5: "* Mineral Water (500ml)",
    price: "৳320",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 178,
    category: "Bangla Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "02.",
    setmenu1: "* Plain Rice",
    setmenu2: "* Beef Bhuna",
    setmenu3: "* Vegetables",
    setmenu4: "* Dal",
    setmenu5: "* Mineral Water (500ml)",
    price: "৳350",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 179,
    category: "Bangla Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "03.",
    setmenu1: "* Plain Rice",
    setmenu2: "* Mutton Curry",
    setmenu3: "* Vegetables",
    setmenu4: "* Dal",
    setmenu5: "* Mineral Water (500ml)",
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 180,
    category: "Bangla Set Menu",
    img: "/assets/images/restaurant menu/default restaurant image.jpg",
    name: "04.",
    setmenu1: "* Plain Rice",
    setmenu2: "* Chicken Curry",
    setmenu3: "* Vegetables",
    setmenu4: "* Dal",
    setmenu5: "* Mineral Water (500ml)",
    price: "৳300",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
];
export default foodItems;

const foodItems = [
  // Snacks
  {
    id: 1,
    category: "Snacks",
    // img: "/assets/images/inner/food-1.jpg",
    img: "/assets/images/cafe menu/Snacks/Club Sandwich.jpg",
    name: "Club Sandwich",
    price: "৳140",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 2,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Chicken Sandwich.jpg",
    name: "Chicken Sandwich",
    price: "৳90",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 3,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/cheese sandwich.jpg",
    name: "Cheese Sandwich",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 4,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Eque Special Burger.jpg",
    name: "Eque Special Burger",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 5,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Beef Burger.jpg",
    name: "Beef Burger",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 6,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Chicken Burger.jpg",
    name: "Chicken Burger",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 7,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Grill Chicken Burger.jpg",
    name: "Grill Chicken Burger",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 8,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/hot dog.jpg",
    name: "Hot Dog",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 9,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Crispy Chicken Fry.jpg",
    name: "Crispy Chicken Fry",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 10,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Chicken toast.jpg",
    name: "Chicken Toast",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 11,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Beef toast.jpg",
    name: "Beef Toast",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 12,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Chicken Cheese Roll.jpg",
    name: "Chicken Cheese Roll",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 13,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Oven Pasta.jpg",
    name: "Oven Pasta",
    price: "৳30",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 14,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Patties.jpg",

    name: "Patties",
    price: "৳30",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 15,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Cupcake.jpg",
    name: "Cupcake",
    price: "৳30",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 16,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Donart.jpg",
    name: "Donart",
    price: "৳70",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 17,
    category: "Snacks",
    img: "/assets/images/cafe menu/Snacks/Faluda.jpg",
    name: "Faluda",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Pizza
  {
    id: 18,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Chicken Pizza.jpg",
    name: 'Chicken Pizza 6"',
    price: "৳380",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 19,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Chicken Pizza.jpg",
    name: 'Chicken Pizza 8"',
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 20,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Chicken Pizza.jpg",
    name: 'Chicken Pizza 10"',
    price: "৳750",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 21,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Chicken Pizza.jpg",
    name: 'Chicken Pizza 12"',
    price: "৳900",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 22,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Chicken Pizza.jpg",
    name: 'Special Chicken Pizza 6"',
    price: "৳440",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 23,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Chicken Pizza.jpg",
    name: 'Special Chicken Pizza 8"',
    price: "৳570",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 24,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Chicken Pizza.jpg",
    name: 'Special Chicken Pizza 10"',
    price: "৳880",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 25,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Chicken Pizza.jpg",
    name: 'Special Chicken Pizza 12"',
    price: "৳1050",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 26,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Beef Pizza.jpg",
    name: 'Beef Pizza 6"',
    price: "৳400",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 27,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Beef Pizza.jpg",
    name: 'Beef Pizza 8"',
    price: "৳620",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 28,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Beef Pizza.jpg",
    name: 'Beef Pizza 10"',
    price: "৳800",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 29,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Beef Pizza.jpg",
    name: 'Beef Pizza 12"',
    price: "৳950",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 30,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Beef Pizza.jpg",
    name: 'Special Beef Pizza 6"',
    price: "৳520",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 31,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Beef Pizza.jpg",
    name: 'Special Beef Pizza 8"',
    price: "৳780",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 32,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Beef Pizza.jpg",
    name: 'Special Beef Pizza 10"',
    price: "৳920",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 33,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Special Beef Pizza.jpg",
    name: 'Special Beef Pizza 12"',
    price: "৳1120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 34,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Eque Special Pizza.jpg",
    name: 'Eque Special Pizza 6"',
    price: "৳600",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 35,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Eque Special Pizza.jpg",
    name: 'Eque Special Pizza 8"',
    price: "৳850",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 36,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Eque Special Pizza.jpg",
    name: 'Eque Special Pizza 10"',
    price: "৳980",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 37,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Eque Special Pizza.jpg",
    name: 'Eque Special Pizza 12"',
    price: "৳1200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 38,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Four Season Pizza.jpg",
    name: 'Four Season Pizza 6"',
    price: "৳380",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 39,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Four Season Pizza.jpg",
    name: 'Four Season Pizza 8"',
    price: "৳500",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 40,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Four Season Pizza.jpg",
    name: 'Four Season Pizza 10"',
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 41,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Four Season Pizza.jpg",
    name: 'Four Season Pizza 12"',
    price: "৳900",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 42,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Mexican Hot Pizza.jpg",
    name: 'Mexican Hot Pizza 6"',
    price: "৳420",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 43,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Mexican Hot Pizza.jpg",
    name: 'Mexican Hot Pizza 8"',
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 44,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Mexican Hot Pizza.jpg",
    name: 'Mexican Hot Pizza 10"',
    price: "৳750",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 45,
    category: "Pizza",
    img: "/assets/images/cafe menu/Pizza/Mexican Hot Pizza.jpg",
    name: 'Mexican Hot Pizza 12"',
    price: "৳950",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Cake
  {
    id: 46,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/Vanilla Pastry.jpg",
    name: "Vanilla Pastry",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 47,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/Chocolate Pastry.jpg",
    name: "Chocolate Pastry",
    price: "৳90",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 48,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/Black Forest Pastry.jpg",
    name: "Black Forest Pastry",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 49,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/Chocolate Much Pastry.jpg",
    name: "Chocolate Much Pastry",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 50,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/Red Velvet Cake.jpg",
    name: "Red Velvet Cake",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 51,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Red Velvet Cake",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 52,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "BLueberry Pastry",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 53,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Strawberry Pastry",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 54,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Orange Much Pastry",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 55,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/1Pound Vanilla Cake.jpg",
    name: "1 Pound Vanilla Cake",
    price: "৳450",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 56,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/1Pound Chocolate Cake.jpg",
    name: "1 Pound Chocolate Cake",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 57,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/1Pound Black Forest.jpg",
    name: "1 Pound Black Forest",
    price: "৳600",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 58,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/1Pound White Fores.jpg",
    name: "1 Pound White Forest",
    price: "৳550",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 59,
    category: "Cake",
    img: "/assets/images/cafe menu/Cake/1Pound Red Velvet Cake.jpg",
    name: "1 Pound Red Velvet Cake",
    price: "৳650",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 60,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Chocolate Much 1 Pound",
    price: "৳850",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 61,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Orange Much 1 Pound",
    price: "৳850",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 62,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Strawberry Cheesecake Much 1 Pound",
    price: "৳1200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 63,
    category: "Cake",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Blueberry Cheesecake Much 1 Pound",
    price: "৳1200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  // Juice
  {
    id: 64,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Mango Juice.jpg",
    name: "Mango Juice",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 65,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Mango with Milk.jpg",
    name: "Mango with Milk",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 66,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Mango Shake.jpg",
    name: "Mango Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 67,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Vanilla Shake.jpg",
    name: "Vanilla Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 68,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Banana with Milk.jpg",
    name: "Banana with Milk",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 69,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Milk Shake.jpg",
    name: "Milk Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 70,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Watermelon Juice.jpg",
    name: "Watermelon Juice",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 71,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Pineapple Juice.jpg",
    name: "Pineapple Juice",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 72,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Pomegranate Juice.jpg",
    name: "Pomegranate Juice",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 73,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Red Apple.jpg",
    name: "Red Apple",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 74,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Green Apple.jpg",
    name: "Green Apple",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 75,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Orange Juice.jpg",
    name: "Orange Juice",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 76,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Lemon Juice.jpg",
    name: "Lemon Juice",
    price: "৳80",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 77,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Lemon with Mint.jpg",
    name: "Lemon with Mint",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 78,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Mango with Ice-cream",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 79,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Banana Ice-cream",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 80,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Fruit Salad.jpg",
    name: "Fruit Salad",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 81,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Fruit Salad & Juice",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 82,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Fruit Salad with Ice-cream",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 83,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Mixed Juice",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 84,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Espahani Juice",
    price: "৳220",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 85,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Cashew nut with milk.jpg",
    name: "Cashew nut with milk",
    price: "৳180",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 86,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Papaya Juice.jpg",
    name: "Papaya Juice",
    price: "৳100",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 87,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Oreo Shake",
    price: "৳160",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 88,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Strawberry Milk Shake.jpg",
    name: "Strawberry Milk Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 89,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Chocolate Milk Shake.jpg",
    name: "Chocolate Milk Shake",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 90,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Grapes Juice.jpg",
    name: "Grapes Juice",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 91,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Naspati Juice",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 92,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Crapefruit Juice",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 93,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Strawberry Juice",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 94,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Kiwi Juice",
    price: "৳200",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 95,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Cocktail Juice",
    price: "৳188",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 96,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Beetroot Juice",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 97,
    category: "Juice",
    img: "/assets/images/cafe menu/Juice/Guava Juice.jpg",
    name: "Guava Juice",
    price: "৳120",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 98,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Lemonmint with ginger",
    price: "৳150",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
  {
    id: 99,
    category: "Juice",
    img: "/assets/images/cafe menu/default cafe image.jpg",
    name: "Eque Special Juice",
    price: "৳250",
    description:
      "Experience a delightful fusion of taste and texture with our expertly prepared, high-quality, and flavorful dishes.",
  },
];
export default foodItems;

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import roomsArray from "../../roomData.js";
import styled from "styled-components";

const OffersArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  const options = {
    loop: true,
    margin: 20,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <Wrapper>
      <div className="offers-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title two">
                <h4>Resort's offers</h4>
                <h1>limited period</h1>
                <h1>room offers</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {rooms.length > 0 && (
              <OwlCarousel className="offers-list" {...options}>
                {rooms &&
                  rooms.map((room) => {
                    return (
                      <div key={room.id} className="col-md-12">
                        <div className="single-offers-box">
                          <div className="offers-thumb">
                            <img src={room.img} alt="" />
                          </div>
                          <div className="offers-content">
                            <Link to={`/room-details/${room.id}`}>
                              {room.subtitle}
                            </Link>
                          </div>
                          <div className="offers-dollar">
                            <h4>{room.offer} off</h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 100%;
    transform: translateY(-100%);
  }

  .owl-prev,
  .owl-next {
    background-color: #000 !important;
    border: 1px solid #fff !important;
    font-size: 30px !important;
    cursor: pointer;
    color: #fff !important;
    border-radius: 4px !important;
    width: 50px !important;
    height: 50px !important;
  }

  .owl-prev:hover,
  .owl-next:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 768px) {
    .owl-nav {
      top: 18% !important;
    }

    .owl-prev,
    .owl-next {
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

export default OffersArea;

const blogData = [
  {
    id: 1,
    image: "/assets/images/blog-image/blog-img-1.jpeg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-1.MP4",
    title: "Luxury Hotel for Travelling Spot, Saidpur, Nilphamari.",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Welcome to Eque Heritage Hotel and Resort in Saidpur, a luxurious retreat where traditional charm and modern elegance come together seamlessly. Set amidst breathtaking landscapes, our resort is the perfect getaway for travelers looking for both relaxation and safety. We offer world-class facilities, from lavish rooms to exceptional dining that showcases local flavors. Our dedication to sustainability means your stay not only supports local communities but also provides an unmatched luxury experience. Engage in activities designed to highlight Saidpur's rich culture and history, and let our attentive staff cater to your every desire, guaranteeing a stay you'll never forget. At Eque Heritage Hotel and Resort, discover how we redefine luxury by creating lasting memories in a stunning location.",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
  {
    id: 2,
    image: "/assets/images/blog-image/blog-img-2.jpg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-2.mp4",
    title:
      "Experience Tranquility and Comfort at Eque Heritage Hotel & Resort in Saidpur!",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Welcome to Eque Heritage Hotel & Resort, a delightful giveaway tucked away in the heart of Saidpur, where comfort and calmness await. Our resort, with its charming gardens, is an ideal escape for families and couples looking to relax and rekindle their connection with the great outdoors. Savor delicious meals at our on-site restaurant, or enjoy the convenience of round-the-clock room service. Our spacious guest rooms each offer a private balcony overlooking the beautiful gardens, a comfortable closet, and modern conveniences such as air conditioning and flat-screen TVs. For those who love the outdoors, our swimming pool provides a refreshing escape. And with free WiFi and parking, your stay will be hassle-free. Situated just a stone's throw from Saidpur Airport, Eque Heritage is your portal to a comfortable and restful experience. Come and uncover the unique charm of our resort; we can't wait to welcome you for a stay you'll never forget!",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
  {
    id: 3,
    image: "/assets/images/home-1/blog-3.jpg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-1.MP4",
    title: "Luxury Hotel for Travelling Spot USA, California",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Rapidiously evolve pandemic experiences and end-to-end platforms. Dramatically administrate inexpensive users with integrated experiences Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
  {
    id: 4,
    image: "/assets/images/home-1/blog-4.jpg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-2.mp4",
    title: "How to Book a Room online Step by Step Guide",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Rapidiously evolve pandemic experiences and end-to-end platforms. Dramatically administrate inexpensive users with integrated experiences Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
  {
    id: 5,
    image: "/assets/images/home-1/blog-5.jpg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-1.MP4",
    title: "Luxury Hotel for Travelling Spot Sandigo, UK",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Rapidiously evolve pandemic experiences and end-to-end platforms. Dramatically administrate inexpensive users with integrated experiences Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
  {
    id: 6,
    image: "/assets/images/home-1/blog-6.jpg",
    date: "August 10, 2023",
    category: "Interior",
    video: "/assets/video/eque-video-2.mp4",
    title: "5 Discount Period every year for Valuable Clients",
    detailsLink: "/blog-details",
    readMoreLink: "/blog",
    description:
      "Rapidiously evolve pandemic experiences and end-to-end platforms. Dramatically administrate inexpensive users with integrated experiences Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
    rules: {
      title: "Rules & Regulations",
      description:
        "Collaboratively redefine cutting-edge infrastructures whereas open main convergence energistically simplify discover. Quickly leverage other's collaborative innovation after next-generation applications.",
      items: [
        "Phosfluorescently envisioneer process done",
        "Rapidiously deliver progressive experiences rather",
        "Professionally actualize intuitive products via multifunctional",
        "Conveniently extend covalent metrics",
      ],
    },
    additionalThumbnails: [
      "/assets/images/inner/blog-details-2.jpg",
      "/assets/images/inner/blog-details-3.jpg",
    ],
    additionalDescriptions: [
      "Interactively visualize top-line internal or 'organic' sources rather than top-line niche markets. Continually unleash 24/7 opportunities after high standards in process improvements. Uniquely deploy impactful are methodologies with reliable information. Synergistically revolutionize fully researched manufactured items with optimal materials competently envisioneer.",
      "Holisticly innovate global ROI with user-centric total linkage. Collaboratively e-enable efficient markets with multifunctional e-business. Continually incentivize sustainable products for B2B.",
    ],
  },
];

export default blogData;

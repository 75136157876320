/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const PricingPageArea = () => {
  return (
    <div className="pricing-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section-title center inner">
              <div className="section-thumb">
                <img src="/assets/images/home-1/section-shape111.png" alt="" />
              </div>
              <h1>Extra Pricing Plans</h1>
              <p className="section-desc-1">
                Proactively morph optimal infomediaries rather than accurate
                expertise. Intrinsicly progressive resources rather than
                resource-leveling
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-thumb">
                <img src="/assets/images/inner/pricing-1.jpg" alt="" />
                <div className="pircing-text">
                  <h4>Room Services</h4>
                </div>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h4>$12</h4>
                  <span>/ Daily</span>
                </div>
                <div className="pricing-item">
                  <ul>
                    <li>Bed and floor Celaning</li>
                    <li>Orci miss natoque vasa ince</li>
                    <li>Clean sorem ipsum morbin</li>
                    <li>Wash & Clean equipment</li>
                  </ul>
                </div>
                <div className="pricing-button">
                  <a href="#">Purchaces Now</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-thumb">
                <img src="/assets/images/inner/pricing-2.jpg" alt="" />
                <div className="pircing-text">
                  <h4>Room Breakfast</h4>
                </div>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h4>$10</h4>
                  <span>/ Daily</span>
                </div>
                <div className="pricing-item">
                  <ul>
                    <li>Bed and floor Celaning</li>
                    <li>Orci miss natoque vasa ince</li>
                    <li>Clean sorem ipsum morbin</li>
                    <li>Wash & Clean equipment</li>
                  </ul>
                </div>
                <div className="pricing-button">
                  <a href="#">Purchaces Now</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-thumb">
                <img src="/assets/images/inner/pricing-3.jpg" alt="" />
                <div className="pircing-text">
                  <h4>Drinks Includes</h4>
                </div>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h4>$09</h4>
                  <span>/ Daily</span>
                </div>
                <div className="pricing-item">
                  <ul>
                    <li>Bed and floor Celaning</li>
                    <li>Orci miss natoque vasa ince</li>
                    <li>Clean sorem ipsum morbin</li>
                    <li>Wash & Clean equipment</li>
                  </ul>
                </div>
                <div className="pricing-button">
                  <a href="#">Purchaces Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPageArea;

import React, { useEffect, useState } from "react";
import roomsArray from "../../roomData.js";
import styled from "styled-components";
import { Link } from "react-router-dom";

const RoyellaBookingArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  // ===== modal =====
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModalOpen = () => setIsAddModalOpen(true);
  const closeAddModalOpen = () => setIsAddModalOpen(false);

  const qunantityArray = [
    { id: 1, quantity: 1 },
    { id: 2, quantity: 2 },
    { id: 3, quantity: 3 },
    { id: 4, quantity: 4 },
    { id: 5, quantity: 5 },
  ];

  return (
    <Wrapper>
      <div className="booking-area home-1">
        <div className="container">
          {/* <form
          action="https://formspree.io/f/myyleorq"
          method="POST"
          id="dreamit-form"
        > */}
          <div className="row add-bg align-items-center">
            <div className="booking-input-box">
              <h4>Check In</h4>
              <input type="date" required />
            </div>

            <div className="booking-input-box">
              <h4>Check Out</h4>
              <input type="date" required />
            </div>

            <div className="booking-input-box">
              <h4>Rooms</h4>
              <select name="rooms" id="rooms">
                <option value="">Select Room</option>
                {rooms &&
                  rooms.map((item) => {
                    return (
                      <option key={item.id} value={item.subtitle}>
                        {item.subtitle}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="booking-input-box upper">
              <h4>Guests</h4>
              <select name="place" id="place">
                <option value="saab">01 Adult, 0 Child</option>
                <option value="saab">02 Adult, 0 Child</option>
                <option value="opel">02 Adult, 1 Child</option>
                <option value="audi">02 Adult, 2 Child</option>
                <option value="audi">02 Adult, 3 Child</option>
              </select>
              {/* <select name="guests" id="guests">
                <option value="">Select Guest</option>
                {rooms &&
                  rooms.map((item) => {
                    return (
                      <option key={item.id} value={item.details}>
                        {item.details}
                      </option>
                    );
                  })}
              </select> */}
            </div>

            <div className="booking-input-box upper">
              <h4>Quantity</h4>
              <select name="quantity" id="quantity">
                <option value="">Select Quantity</option>
                {qunantityArray &&
                  qunantityArray.map((item) => {
                    return (
                      <option key={item.id} value={item.quantity}>
                        {item.quantity}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="booking-button">
              <button onClick={openAddModalOpen}>Book Now</button>
            </div>
          </div>
          {/* </form> */}
          <div id="status"></div>
        </div>

        {/* ===== Custom Modal ===== */}
        {isAddModalOpen && (
          // <div className="custom-modal">
          //   <div className="modal-content">
          //     <span className="close" onClick={closeAddModalOpen}>
          //       &times;
          //     </span>
          //     <div className="d-flex justify-content-between align-items-center">
          //       <div className="">
          //         <h2 className="">Logo</h2>
          //         <h2 className="mb-0">EQUE HERITAGE HOTEL AND RESORT</h2>
          //         <p>Feel The Nature</p>
          //       </div>
          //       <div className="gust-card">
          //         <h2 className="mb-2">Guest's Information Card</h2>
          //       </div>
          //     </div>

          //     <form>
          //       <div className="form_title d-flex justify-content-start align-items-center gap-5">
          //         <p className="">
          //           <span className="text-danger">*</span>Name
          //         </p>
          //         <p>
          //           :
          //           -----------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="d-flex justify-content-start align-items-center gap-4">
          //         <div className="d-flex justify-content-center align-items-center gap-1">
          //           <input type="checkbox" className="mt-2" />
          //           <label>Male</label>
          //         </div>
          //         <div className="d-flex justify-content-center align-items-center gap-1">
          //           <input type="checkbox" className="mt-2" />
          //           <label>Female</label>
          //         </div>
          //         <div className="d-flex justify-content-center align-items-center gap-1">
          //           <input type="checkbox" className="mt-2" />
          //           <label>Age</label>
          //         </div>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-danger">*</span>Father's/ Husband Name
          //         </p>
          //         <p>
          //           :
          //           ---------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-danger">*</span>Present Address
          //         </p>
          //         <p>
          //           :
          //           -------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-white">*</span>Permanent Address
          //         </p>
          //         <p>
          //           :
          //           ---------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className=" d-flex justify-content-start align-items-center">
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-white">*</span>Profession
          //           </p>
          //           <p>: ------------------------------</p>
          //         </div>
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-danger">*</span>Degination
          //           </p>
          //           <p>: --------------------------------</p>
          //         </div>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-white">*</span>Office Address (If any)
          //         </p>
          //         <p>
          //           :
          //           -----------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-danger">*</span>Nation ID/ Passport No
          //         </p>
          //         <p>
          //           :
          //           -----------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className=" d-flex justify-content-start align-items-center">
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-danger">*</span>Arrive Form
          //           </p>
          //           <p>: -------------------------------</p>
          //         </div>
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-white">*</span>Going to
          //           </p>
          //           <p>: --------------------------------</p>
          //         </div>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-white">*</span>Purpose Of Tour
          //         </p>
          //         <p>
          //           :
          //           --------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className=" d-flex justify-content-start align-items-center">
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-danger">*</span>Date Of Arrival
          //           </p>
          //           <p>: --------------------------------</p>
          //         </div>
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-danger">*</span>Time
          //           </p>
          //           <p>: --------------------------------</p>
          //         </div>
          //       </div>

          //       <div className=" d-flex justify-content-start align-items-center">
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-danger">*</span>Date Of Departure
          //           </p>
          //           <p>: ------------------------------</p>
          //         </div>
          //         <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //           <p className="">
          //             <span className="text-white">*</span>Time
          //           </p>
          //           <p>: -------------------------------</p>
          //         </div>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-white">*</span>Referred By
          //         </p>
          //         <p>
          //           :
          //           --------------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-danger">*</span>Relation Status
          //         </p>
          //         <p>
          //           :
          //           ----------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div class="note mb-5">
          //         <p>
          //           [The Management is not responsible for valuables left
          //           unattended in rooms. Safe deposit boxes are available at the
          //           front office.]
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-danger">*</span>Mobile No
          //         </p>
          //         <p>
          //           :
          //           ----------------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="form_title d-flex justify-content-start align-items-center gap-2">
          //         <p className="">
          //           <span className="text-white">*</span>E-mail
          //         </p>
          //         <p>
          //           :
          //           ---------------------------------------------------------------------------------------
          //         </p>
          //       </div>

          //       <div className="modal-actions">
          //         <button type="reset" className="cancel-btn">
          //           Cancel
          //         </button>
          //         <button type="submit" className="add-btn">
          //           Submit
          //         </button>
          //       </div>
          //     </form>
          //   </div>
          // </div>

          <div className="custom-modal">
            <div className="modal-content" style={{ marginTop: "970px" }}>
              <span className="close" onClick={closeAddModalOpen}>
                &times;
              </span>

              <div className="d-flex justify-content-center align-items-center flex-column main_content">
                <div className="header-logo text-center mb-3">
                  <Link to="/">
                    <img
                      src="/assets/images/logo/eque-logo-111.png"
                      alt="Logo"
                      style={{ width: "104px", height: "62px" }}
                    />
                  </Link>
                </div>
                <div className="guest_card text-center">
                  <h2 className="mb-2 mt-2 text-center">
                    Guest's Information Card
                  </h2>
                </div>
                <div>
                  <h2 className="mb-0 text-center">
                    EQUE HERITAGE HOTEL AND RESORT
                  </h2>
                  <p className="text-center">Feel The Nature</p>
                </div>
              </div>

              <form>
                <div className="mb-3">
                  <label className="form-label mb-0 mb-0">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter your name"
                    required
                  />
                </div>

                <div className="d-flex justify-content-start align-items-center gap-4">
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <label className="form-label">
                      Gender<span className="text-danger">*</span>
                    </label>
                    <span className="text-danger"></span>
                    <input type="checkbox" className="mt-2" />
                    <label>Male</label>
                  </div>

                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <span className="text-danger"></span>
                    <input type="checkbox" className="mt-2" />
                    <label>Female</label>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Age
                    <span className="text-danger"></span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Age"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Father's/Husband's Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Father's/Husband's Name"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Present Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Present Address"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">Permanent Address</label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Permanent Address"
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">Profession</label>
                    <input
                      type="text"
                      className="form-control mb-0 mt-0"
                      placeholder="Enter Profession"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">
                      Designation <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-0 mt-0"
                      placeholder="Enter Designation"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    National ID/Passport No{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter ID or Passport No"
                    required
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">
                      Date of Arrival <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control mb-0 mt-0"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">
                      Time <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control mb-0 mt-0"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">Date of Departure</label>
                    <input type="date" className="form-control mb-0 mt-0" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label mb-0">Time</label>
                    <input type="time" className="form-control mb-0 mt-0" />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Referred By <span className="text-danger"></span>
                  </label>
                  <input
                    type="tel"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Referred By"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Relation Status <span className="text-danger">*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Relation Status"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">
                    Mobile No <span className="text-danger">*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Mobile Number"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label mb-0">Email</label>
                  <input
                    type="email"
                    className="form-control mb-0 mt-0"
                    placeholder="Enter Email"
                  />
                </div>

                <div className="note alert alert-warning">
                  <p>
                    [The Management is not responsible for valuables left
                    unattended in rooms. Safe deposit boxes are available at the
                    front office.]
                  </p>
                </div>

                <div className="modal-actions d-flex justify-content-end gap-3">
                  <button type="reset" className="btn btn-secondary">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .guest_card {
    background-color: #0f9f58;
    border-radius: 4px;
    padding: 1px 15px;
  }
  .guest_card h2 {
    color: #fff;
  }
  .form_title h5 {
    font-size: 15px;
  }

  /* ======= Modal styles ======= */
  .custom-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    /* max-width: 500px; */
    max-width: 700px;
    position: relative;
    margin-top: 300px;
  }
  .modal-content h2 {
    font-size: 18px;
    font-weight: 700;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }

  .close:hover {
    color: #000;
  }

  .modal-content h2 {
    margin-bottom: 20px;
  }

  .modal-content form label {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    color: #000;
  }

  .modal-content form input,
  .modal-content form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* outline: 1px solid #82a8d1 !important; */
  }
  .modal-content form input:focus,
  .modal-content form select:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  input,
  optgroup,
  select,
  textarea {
    font-size: 12px;
  }
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }

  .modal-actions .cancel-btn,
  .modal-actions .add-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }

  .modal-actions .cancel-btn {
    background-color: #ff6e6c;
    color: #fff;
  }

  .modal-actions .add-btn {
    background-color: #007bff;
    color: #fff;
  }

  .modal-actions .cancel-btn:hover {
    background-color: #e77b79;
  }

  .modal-actions .add-btn:hover {
    background-color: #4497f0;
  }

  @media screen and (max-width: 1024px) {
    .booking-input-box {
      position: relative;
      width: 17%;
      display: inline-block;
    }
  }
  @media screen and (max-width: 768px) {
    .booking-input-box {
      width: 20%;
    }
    .booking-button {
      margin-top: 3px !important;
    }
  }

  @media screen and (max-width: 425px) {
    .booking-input-box {
      width: 100%;
    }
    .booking-button {
      margin-top: 8px;
    }
    .main_content {
      display: flex;
      flex-direction: column !important;
    }
    .modal-content {
      margin-top: 1255px !important;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }

  @media screen and (max-width: 375px) {
    .modal-content {
      margin-top: 1285px !important;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }

  @media screen and (max-width: 320px) {
    .modal-content {
      margin-top: 1300px !important;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
`;

export default RoyellaBookingArea;

import React from "react";
import CafeHeroSection from "./CafeHeroSection";
import CafeFoodMenuArea from "./CafeFoodMenuArea";

const EqueCafe = () => {
  return (
    <>
      <CafeHeroSection />
      <CafeFoodMenuArea />
    </>
  );
};

export default EqueCafe;

import React from "react";
import ServiceDetailsHeroArea from "./ServiceDetailsHeroArea";
import ServiceDetailsArea from "./ServiceDetailsArea";
// import ServiceFoodMenuArea from "./ServiceFoodMenuArea";

const ServiceDetails = () => {
  return (
    <>
      <ServiceDetailsHeroArea />
      <ServiceDetailsArea />
      {/* <ServiceFoodMenuArea /> */}
    </>
  );
};

export default ServiceDetails;

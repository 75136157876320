const serviceData = [
  {
    id: 1,
    category: "Unpaid",
    title: "Welcome Drinks",
    subtitle: "Fitness",
    description: `Enjoy a Complimentry Welcome Drink	Enjoy a complimentary welcome drink exclusively for our room guests upon check-in.`,
    image: "/assets/images/services/WELCOM DRINKS.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 2,
    category: "Unpaid",
    title: "Fruits Buffet Basket",
    subtitle: "Fitness",
    description: `Fresh fruits, specially curated for our Royal Suite guests to enhance your luxurious stay."`,
    image: "/assets/images/services/FRUITS BUSKET.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 3,
    category: "Unpaid",
    title: "Complimentary Breakfast ",
    subtitle: "Fitness",
    description: `Our complimentary buffet breakfast is exclusively reserved for registered room guests, offering an extensive selection of fresh cuisine and beverages.`,
    image: "/assets/images/home-1/facilities-thumb-3.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 4,
    category: "Unpaid",
    title: "Daily News paper",
    subtitle: "Fitness",
    description: `"Daily Newspaper" available only to our in-house room guests, keeping them updated on current events and local happenings during their stay.`,
    image: "/assets/images/services/NEWSPAPER.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 5,
    category: "Unpaid",
    title: "Free Car parking",
    subtitle: "Fitness",
    description: `Complimentary parking is available for registered guests of our resort.`,
    image: "/assets/images/services/Free Car parking.jpeg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 6,
    category: "Unpaid",
    title: "24hrs Front Desk service",
    subtitle: "Fitness",
    description: `"We offer a dedicated 24-hour front desk service exclusively for the convenience and satisfaction of our in-house room guests."`,
    image: "/assets/images/services/24hrs Front Desk service.jpeg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 7,
    category: "Unpaid",
    title: "Swimming pool (1 hour) Daily",
    subtitle: "Fitness",
    description: `The swimming pool is available for our room guests to enjoy on a daily basis for up to one hour.`,
    image: "/assets/images/services/Swimming pool (1 hour) Daily.JPG",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 8,
    category: "Unpaid",
    title: "Wi-Fi service",
    subtitle: "Fitness",
    description: `We want to make your stay as comfortable as possible, so we provide free Wi-Fi in all our guest rooms. This way, you can easily keep in touch with family and friends or get work done while you're here.`,
    image: "/assets/images/services/FREE WIFIF.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 9,
    category: "Unpaid",
    title: "Airport (pick up & drop)",
    subtitle: "Fitness",
    description: `"We offer a complimentary airport pickup and drop-off service for our in-house guests, ensuring a seamless and hassle-free experience from the moment you arrive to the moment you depart."`,
    image: "/assets/images/services/AIRPORT PIC UP & DROP.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 10,
    category: "Unpaid",
    title: "Intercom service",
    subtitle: "Fitness",
    description: `The intercom service is available for guests to use in the Resort area.`,
    image: "/assets/images/services/INTERCOM.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 11,
    category: "Unpaid",
    title: "Luggage storage",
    subtitle: "Fitness",
    description: `For the convenience of our valued room guests, we offer a complimentary luggage storage service at our front desk, where you can safely store your belongings until you check-in or check-out of your room.`,
    image: "/assets/images/services/Luggage storage and wrapping.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 12,
    category: "Unpaid",
    title: "Hot & Cold-Water Service",
    subtitle: "Fitness",
    description: `We're pleased to offer our room guests the convenience of hot and cold water services, available exclusively for their comfort and satisfaction during their stay with us.`,
    image: "/assets/images/services/HOT & COLD WATER.jpg",
    link: "/services-details",
    foods: {
      heading: "The Restaurant Center",
    },

    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 13,
    category: "Paid",
    title: "Rent a car",
    description: `Experience the freedom to explore with our reliable rental cars, perfect for any guest looking to discover the area at their own pace.`,
    image: "/assets/images/feature-area/rent a-car.png",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 14,
    category: "Paid",
    title: "Business Center",
    description: `Business Center	A fully equipped workspace with fast internet and private meeting rooms. This allows guests to easily mix work and relaxation in a peaceful setting.`,
    image: "/assets/images/feature-area/Business Center.jpg",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 15,
    category: "Paid",
    title: "Air ticket",
    description: `Air ticket booking services for guests, ensuring a seamless travel experience with flexible payment options.`,
    image: "/assets/images/feature-area/Air ticket.jpg",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 16,
    category: "Paid",
    title: "Conference Hall",
    description: `The conference hall, with its stunning pillar less design, is available for rent, making it the perfect venue for hosting your guests in a spacious and elegant setting.`,
    image: "/assets/images/feature-area/Conference Hall.jpg",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 17,
    category: "Paid",
    title: "Laundry Service",
    description: `Our personalized laundry service offers convenient and prompt pick-up, expert washing and folding, and secure drop-off at your desired time, all at a competitive rate tailored to fit your specific needs.`,
    image: "/assets/images/feature-area/Laundry Service.png",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
  {
    id: 18,
    category: "Paid",
    title: "Tours Service",
    description: `We have personalized tour services for our guests, ensuring an unforgettable experience tailored to your interests and preferences.`,
    image: "/assets/images/feature-area/Tours Service.jpg",

    foods: {
      heading: "The Restaurant Center",
    },
    dressCode: {
      description: `Professionally deliver fully researched scenarios with turnkey
                    communities competently`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
    terrace: {
      description: `Open the drinks only maintain restaurant rules and regulations below`,
      items: [
        "Quickly generate bricks-and-clicks",
        "Interactively cultivate visionary platforms",
        "Energistically envisioneer resource",
      ],
    },
  },
];
export default serviceData;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import roomsArray from "../../roomData.js";

const FindRoomPageArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  return (
    <div className="find-room-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section-title center inner">
              <h1>Check Availibity</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <form
            action="https://formspree.io/f/myyleorq"
            method="POST"
            id="dreamit-form"
          >
            <div className="row add-bg align-items-center">
              <div className="booking-input-box">
                <h4>Check In</h4>
                <input type="date" required />
              </div>
              <div className="booking-input-box">
                <h4>Check Out</h4>
                <input type="date" required />
              </div>
              <div className="booking-input-box">
                <h4>Rooms</h4>
                <select name="place" id="place">
                  <option value="saab">01 Rooms</option>
                  <option value="opel">02 Rooms</option>
                  <option value="audi">03 Rooms</option>
                  <option value="audi">04 Rooms</option>
                </select>
              </div>
              <div className="booking-input-box upper">
                <h4>Guests</h4>
                <select name="place" id="place">
                  <option value="saab">01 Adult, 0 Child</option>
                  <option value="opel">02 Adult, 1 Child</option>
                  <option value="audi">02 Adult, 2 Child</option>
                  <option value="audi">02 Adult, 3 Child</option>
                </select>
              </div>
              <div className="booking-button">
                <button type="submit">Book Now</button>
              </div>
            </div>
          </form>
          <div id="status"></div>
        </div>

        <div className="row align-items-center">
          {rooms &&
            rooms.map((room) => {
              return (
                <div key={room.id} className="col-lg-4 col-md-6">
                  <div className="room-single-box">
                    <div className="room-thumb">
                      <img src={room.img} alt="" />
                      <div className="room-details-button">
                        <Link to={`/room-details/${room.id}`}>
                          View Details<i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="room-pricing">
                      <span className="dolar">{room.price}</span>
                      <span>Per Night</span>
                    </div>
                    <div className="room-content">
                      <h4>{room.title}</h4>
                      <Link to={`/room-details/${room.id}`}>
                        {room.subtitle}
                      </Link>
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Check in - {room.checkIn}</p>
                        <p>Check out - {room.checkOut}</p>
                      </div>
                    </div>
                    <div className="room-bottom">
                      <div className="room-bottom-icon">
                        <span>
                          <img
                            src="/assets/images/home-1/room-bottom-icon.png"
                            alt=""
                          />
                          {room.details}
                        </span>
                      </div>
                      <div className="coustomar-rating">
                        <ul>
                          <li>
                            <i className="bi bi-star-fill"></i>
                          </li>
                          <li>
                            <i className="bi bi-star-fill"></i>
                          </li>
                          <li>
                            <i className="bi bi-star-fill"></i>
                          </li>
                          <li>
                            <i className="bi bi-star-fill"></i>
                          </li>
                          <li>
                            <i className="bi bi-star-half"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FindRoomPageArea;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CafeHeroSection = () => {
  return (
    <Wrapper>
      <div className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="breacumb-content">
                <div className="breadcum-title">
                  <h4>Cafe</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>&nbsp;/&nbsp;</li>
                  <li>Cafe</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .breadcumb-area {
    /* background: url(../images/inner/breadcumb.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center; */
    padding: 170px 0 120px;
  }
`;

export default CafeHeroSection;

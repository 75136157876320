import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import heroSlidesArray from "../../heroSlidesData.js";

const HeroSection = () => {
  const [heroSlider, setHeroSlider] = useState([]);

  useEffect(() => {
    setHeroSlider(heroSlidesArray);
  }, []);

  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    nav: true,
    dots: true,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>',
    ],
  };

  return (
    <Wrapper>
      {heroSlider.length > 0 && (
        <OwlCarousel className="hero-slider owl-carousel" {...options}>
          {heroSlider.map((slide) => (
            <div
              key={slide.id}
              className={`hero-area ${slide.style} align-items-center d-flex`}
              style={{
                backgroundImage: `url(${slide.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "830px",
                textAlign: "center",
                position: "relative",
              }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="hotel-rating">
                      <ul>
                        <li>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <i
                              className="bi bi-star-fill"
                              style={{ fontSize: "30px" }}
                            ></i>
                            <i
                              className="bi bi-star-fill"
                              style={{ fontSize: "50px" }}
                            ></i>
                            <i
                              className="bi bi-star-fill"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="hero-content">
                      <h1 className="title">{slide.title}</h1>
                      <h4 className="subtitle">{slide.subtitle}</h4>
                      {/* <h1>{slide.location}</h1> */}
                    </div>

                    {/* <div className="luxury-button">
                      <Link to="/about">Discover More</Link>
                    </div> */}

                    <div className="hero-contact">
                      <Link to="tel:+8801317121871">
                        <i className="bi bi-telephone-fill"></i> +8801317121871
                      </Link>
                      <Link to="tel:+8801317121874">
                        <i className="bi bi-telephone-fill"></i> +8801317121874
                      </Link>
                      <Link to="tel:+8801406767000">
                        <i className="bi bi-telephone-fill"></i> +8801406767000
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    border: 1px solid #fff !important;
    color: #fff !important;
    width: 40px !important;
    height: 40px !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff !important;
    font-size: 10px !important;
  }
  .hotel-rating ul li i {
    color: #f9ed13 !important;
  }

  .hero-content .title {
    font-size: 40px !important;
    font-weight: 700 !important;
    font-family: sans-serif !important;
  }
  .hero-content .subtitle {
    font-size: 30px !important;
    font-family: serif !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    color: #0d5f50 !important;
  }
  .hero-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .hero-contact a {
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    /* gap: 5px; */
    text-decoration: none;
    font-weight: 700 !important;
    font-size: 16px;
  }
  .hero-contact i {
    color: #000;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    .hero-content .title {
      font-size: 24px !important;
    }
    .hero-content .subtitle {
      font-size: 24px !important;
    }
  }

  @media screen and (max-width: 425px) {
    .hero-area {
      height: 600px !important;
    }
    .hero-contact {
      display: block !important;
    }
    .hero-content .title {
      font-size: 20px !important;
    }
    .hero-content .subtitle {
      font-size: 20px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .hero-area {
      height: 550px !important;
    }
    .hero-contact {
      display: block !important;
    }
    .hero-content .title {
      font-size: 16px !important;
    }
    .hero-content .subtitle {
      font-size: 13px !important;
    }
  }
`;

export default HeroSection;

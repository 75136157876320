import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import DarkModeToggle from "./components/DarkModeToggle";
import Loader from "./components/Loader";
import Scrollup from "./components/Scrollup";
import Top from "./components/Top";
import BrandArea from "./components/BrandArea";
import Footer from "./components/Footer/Footer";
import FooterReserved from "./components/Footer/FooterReserved";
import NotFound from "./components/NotFound";
// import Cursor from "./components/Cursor";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Room from "./pages/Room/Room";
import FndRoom from "./pages/FindRoom/FndRoom";
import RoomDetails from "./pages/RoomDetails/RoomDetails";

import Resturant from "./pages/Resturant/Resturant";
import EqueCafe from "./pages/EqueCafe/EqueCafe";

import Service from "./pages/Service/Service";
import ServiceDetails from "./pages/ServiceDetails/ServiceDetails";

import Gallery from "./pages/Gallery/Gallery";
// import Team from "./pages/Team/Team";
import Pricing from "./pages/Pricing/Pricing";

import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Contact from "./pages/Contact/Contact";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        // Render Loader component when loading
        <Loader />
      ) : (
        <BrowserRouter>
          <Top />
          <Scrollup />
          <DarkModeToggle />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />

            <Route path="/room" element={<Room />} />
            <Route path="/find-room" element={<FndRoom />} />
            <Route path="/room-details/:id" element={<RoomDetails />} />

            <Route path="/restaurant" element={<Resturant />} />
            <Route path="/cafe" element={<EqueCafe />} />

            <Route path="/service" element={<Service />} />
            <Route path="/services-details/:id" element={<ServiceDetails />} />

            <Route path="/gallery" element={<Gallery />} />
            {/* <Route path="/team" element={<Team />} /> */}
            <Route path="/pricing" element={<Pricing />} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-details/:id" element={<BlogDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <BrandArea />
          <Footer />
          <FooterReserved />
          {/* <Cursor /> */}
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import styled from "styled-components";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const GalleryPageArea = () => {
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const images = [
//     "/assets/images/home-1/gallery-1.jpg",
//     "/assets/images/home-1/gallery-2.jpg",
//     "/assets/images/home-1/gallery-3.jpg",
//     "/assets/images/home-1/gallery-4.jpg",
//     "/assets/images/home-1/gallery-5.jpg",
//     "/assets/images/home-1/gallery-6.jpg",
//   ];

//   const openModal = (index) => {
//     setSelectedImageIndex(index);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setSelectedImageIndex(0);
//     setIsModalOpen(false);
//   };

//   const sliderSettings = {
//     initialSlide: selectedImageIndex,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: true,
//     arrows: true,
//   };

//   return (
//     <Wrapper>
//       <div className="room-area inner">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 text-center">
//               <div className="section-title center inner">
//                 <div className="section-thumb">
//                   <img src="/assets/images/home-1/section-shape1.png" alt="" />
//                 </div>
//                 <h1>Royella’s Rooms & Suites</h1>
//                 <p className="section-desc-1">
//                   Proactively morph optimal infomediaries rather than accurate
//                   expertise. Intrinsicly progressive resources rather than
//                   resource-leveling
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className="designs">
//             {images.map((image, index) => (
//               <div
//                 className="coll"
//                 key={index}
//                 onClick={() => openModal(index)}
//               >
//                 <img src={image} alt={`Design ${index + 1}`} />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="close-modal" onClick={closeModal}>
//               &times;
//             </button>
//             <Slider {...sliderSettings}>
//               {images.map((image, index) => (
//                 <div key={index}>
//                   <img
//                     src={image}
//                     alt={`Selected Design ${index + 1}`}
//                     className="modal-image"
//                     style={{ width: "100%" }}
//                   />
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       )}
//     </Wrapper>
//   );
// };

// const Wrapper = styled.section`
//   .designs {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     flex-wrap: wrap;
//   }

//   .coll {
//     width: calc(33.33% - 10px); /* 33.33% for three images per row */
//     margin-bottom: 20px;
//     cursor: pointer;
//     overflow: hidden; /* Ensures the image stays within its container */
//     border-radius: 4px;

//     img {
//       width: 100%;
//       height: 320px !important;
//       object-fit: cover;
//       transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1); /* Smooth transition */
//     }

//     &:hover img {
//       transform: scale(1.1); /* Scale the image on hover */
//     }
//   }

//   /*====== modal ======*/
//   .modal-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.8);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;
//   }

//   .modal-content {
//     position: relative;
//     border: none;
//     background-color: transparent;
//   }

//   .modal-image {
//     max-width: 100%;
//     height: 500px !important;
//     display: block;
//     border-radius: 4px;
//   }

//   .close-modal {
//     position: fixed;
//     top: 20px;
//     right: 20px;
//     background: transparent;
//     border: none;
//     font-size: 3rem;
//     color: #fff;
//     cursor: pointer;
//     z-index: 1100;
//     line-height: 1;
//   }

//   @media screen and (max-width: 1024px) {
//     .coll {
//       width: calc(50% - 10px); /* Two images per row on medium screens */
//     }
//   }

//   @media screen and (max-width: 768px) {
//     .coll {
//       width: calc(50% - 10px); /* Two images per row on small screens */
//     }
//   }

//   @media screen and (max-width: 480px) {
//     .coll {
//       width: 100%; /* One image per row on extra-small screens */
//     }
//   }
// `;

// export default GalleryPageArea;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import galleryImagesArray from "../../galleryImages.js";

const GalleryPageArea = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setGalleryItems(galleryImagesArray);
  }, [setGalleryItems]);

  // const images = [
  //   "/assets/images/gallery/1 Royal Suite (3).jpg",
  //   "/assets/images/gallery/2 Couple Deluxe (10).jpg",
  //   "/assets/images/gallery/3 Eque-Family Suite (8).jpg",
  //   "/assets/images/gallery/4 Twine Deluxe (8).jpg",
  //   "/assets/images/gallery/5 single delux (5).jpg",
  //   "/assets/images/gallery/6 Eque Single Deluxe (4).jpg",
  //   "/assets/images/gallery/7 Eque Hall Room (1).jpg",
  //   "/assets/images/gallery/mini hall room.jpg",
  //   "/assets/images/gallery/cabin.jpg",
  // ];

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImageIndex(0);
    setIsModalOpen(false);
  };

  const CustomNextArrow = ({ onClick }) => (
    <button className="custom-arrow next-arrow" onClick={onClick}>
      &#8250;
    </button>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <button className="custom-arrow prev-arrow" onClick={onClick}>
      &#8249;
    </button>
  );

  const sliderSettings = {
    initialSlide: selectedImageIndex,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <Wrapper>
      <div className="room-area inner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img src="/assets/images/home-1/section-shape111.png" alt="" />
                </div>
                <h1>Resort's gallery</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>

          <div className="designs">
            {galleryItems &&
              galleryItems.map((img, index) => (
                <div
                  className="coll"
                  key={index}
                  onClick={() => openModal(index)}
                >
                  <img src={img.image} alt={`Design ${index + 1}`} />
                </div>
              ))}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            {galleryItems.length > 0 && (
              <Slider {...sliderSettings}>
                {galleryItems &&
                  galleryItems.map((img, index) => (
                    <div key={index}>
                      <img
                        src={img.image}
                        alt={`Selected Design ${index + 1}`}
                        className="modal-image"
                        style={{ width: "100%" }}
                      />
                    </div>
                  ))}
              </Slider>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .designs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .coll {
    width: calc(33.33% - 10px);
    margin-bottom: 20px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;

    img {
      width: 100%;
      height: 320px !important;
      object-fit: cover;
      transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  /* ====== modal ====== */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    position: relative;
    border: none;
    background-color: transparent;
  }

  .modal-image {
    max-width: 100% !important;
    height: 500px !important;
    display: block;
    border-radius: 4px;
  }

  .close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    z-index: 1100;
    line-height: 1;
  }

  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 2px 10px;
    border-radius: 10%;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1100;
  }

  .next-arrow {
    right: -50px;
    /* background-color: #fff !important; */
  }

  .prev-arrow {
    left: -50px;
    /* background-color: #fff !important; */
  }

  @media screen and (max-width: 1024px) {
    .coll {
      width: calc(50% - 10px);
    }
  }

  @media screen and (max-width: 768px) {
    .coll {
      width: calc(50% - 10px);
    }
    .modal-content {
      width: 60% !important;
    }
    .modal-image {
      /* max-width: 100%; */
      height: 400px !important;
    }
  }

  @media screen and (max-width: 480px) {
    .coll {
      width: 100%;
    }
  }
  @media screen and (max-width: 425px) {
    .coll {
      width: 100%;
    }
    .modal-image {
      /* max-width: 100%; */
      height: 300px !important;
    }
  }

  @media screen and (max-width: 375px) {
    .modal-image {
      /* max-width: 100%; */
      height: 300px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .modal-image {
      /* max-width: 100%; */
      height: 250px !important;
    }
  }
`;

export default GalleryPageArea;

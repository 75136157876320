import React from "react";
import HeroSection from "./HeroSection";
import RoyellaBookingArea from "./RoyellaBookingArea";
import RoomTitleArea from "./RoomTitleArea";
import AboutArea from "./AboutArea";
import RoomArea from "./RoomArea";
import FeatureArea from "./FeatureArea";
import ManagerCallDoArea from "./ManagerCallDoArea";
import ServicesArea from "./ServicesArea";
import OffersArea from "./OffersArea";
import TestimonialArea from "./TestimonialArea";
import BlogArea from "./BlogArea";
// import DarkModeToggle from "../../components/DarkModeToggle";

const Home = () => {
  return (
    <>
      {/* <DarkModeToggle /> */}
      <HeroSection />
      <RoyellaBookingArea />
      <AboutArea />
      <RoomTitleArea />
      <RoomArea />
      {/* <AboutArea /> */}
      <FeatureArea />
      <ManagerCallDoArea />
      <ServicesArea />
      <OffersArea />
      <TestimonialArea />
      <BlogArea />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close mobile menu when a link is clicked
  const closeMenuOnLinkClick = () => {
    setMenuOpen(false);
  };
  return (
    <Wrapper>
      <div
        className={`header-area inner ${isSticky ? "sticky" : ""}`}
        id="sticky-header"
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="header-logo">
                <Link to="/">
                  {/* <img src="/assets/images/home-1/logo-1.png" alt="Logo" /> */}
                  <img
                    className="logo-img"
                    src="/assets/images/logo/Eque logo (4).png"
                    alt="Logo"
                    style={{ width: "174px", height: "62px" }}
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="header-menu">
                <ul>
                  <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      About
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/room"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Accommodation
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/restaurant"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Restaurant
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/cafe"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Cafe
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/service"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Service
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/gallery"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Gallery
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="blog"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 text-end">
              <div className="header-button text-right">
                <Link to="/room" className="bookinng">
                  Booking Online
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu-area sticky d-sm-block d-md-block d-lg-none">
        <div className="mobile-menu mean-container">
          <div className="mean-bar">
            <Link
              to="#"
              className="meanmenu-reveal meanclose"
              onClick={toggleMenu}
              style={{
                right: 0,
                left: "auto",
                textAlign: "center",
                textIndent: "0px",
                fontSize: "18px",
              }}
            >
              {menuOpen ? (
                "X"
              ) : (
                <>
                  <span></span>
                  <span></span>
                  <span></span>
                </>
              )}
            </Link>

            {menuOpen && (
              <nav className="mean-nav">
                <ul className="nav_scroll">
                  <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      About
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/room"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Accommodation
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/restaurant"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Restaurant
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/cafe"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Cafe
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/service"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Service
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/gallery"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Gallery
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/blog"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : ""
                      }
                      onClick={closeMenuOnLinkClick}
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .nav-link-active {
    color: #c19d68 !important;
  }

  .mean-container .mean-bar::before {
    color: #fff;
    content: "Eque Hotel & Resort";
    font-size: 28px;
    font-weight: 600;
    left: 10px;
    position: absolute;
    top: 18px;
  }

  .inner .header-button a {
    border: 1px solid #fff !important;
    background: transparent !important;
    color: #fff !important;
  }

  .inner .header-button a:before {
    background: #c19d68 !important;
  }
  .header-menu ul li a {
    font-size: 16px !important;
  }

  .header-button a {
    font-size: 16px !important;
  }
  .mean-bar,
  .mean-nav {
    transition: opacity 0.3s ease !important;
  }

  @media screen and (max-width: 1440px) {
    .header-button a {
      font-size: 14px !important;
    }

    .header-menu ul li a {
      font-size: 12px !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .header-logo .logo-img {
      width: 140px !important;
    }
    .header-button a {
      font-size: 9px !important;
      padding: 7px, 10px !important;
    }
    .header-button {
      padding: 7px, 10px !important;
    }
    .header-menu ul li a {
      font-size: 11px !important;
    }
  }
`;

export default Header;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import servicesDetailsArray from "../../serviceData.js";

const ServiceDetailsHeroArea = () => {
  const [servicesDetails, setServicesDetails] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setServicesDetails(servicesDetailsArray);
  }, []);

  const findServiceDetails =
    servicesDetails &&
    servicesDetails.find(
      (serviceDetails) => serviceDetails.id === parseInt(id)
    );

  return (
    <Wrapper>
      <div className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="breacumb-content">
                <div className="breadcum-title">
                  <h4 className="fs-1 mb-0">Service Details</h4>
                  <h4 className="mt-0">{findServiceDetails && findServiceDetails.title}</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>&nbsp;/&nbsp;</li>
                  <li>Service Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .breadcumb-area {
    /* background: url(../images/inner/breadcumb.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center; */
    padding: 170px 0 120px;
  }
`;

export default ServiceDetailsHeroArea;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterReserved = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Wrapper>
      <div className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-bottom-content">
                {/* <h4>© 2023, Royella. All Rights Reserved.</h4> */}
                <div className="copyright-column">
                  <div className="copyright">
                    <Link
                      to="https://www.ekattorit.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <small className="small">
                        <small
                          className="footer text-white"
                          style={{ fontSize: "11px" }}
                        >
                          &copy; Copyright {currentYear},
                        </small>
                        &nbsp;
                        <span className="text-white">
                          Designed & Developed By&nbsp;
                        </span>
                        <span style={{ color: "red" }}>EKATTOR</span>
                        <span style={{ color: "green" }}> iT</span>
                      </small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

export default FooterReserved;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import blogArray from "../../blogData.js";

const BlogPageArea = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogArray);
  }, []);

  return (
    <Wrapper>
      <div className="blog-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img
                    src="/assets/images/home-1/section-shape111.png"
                    alt=""
                  />
                </div>
                <h1>Resort's blog</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {blogs &&
                  blogs.map((blog) => {
                    return (
                      <div key={blog.id} className="col-lg-4 col-md-6">
                        <div className="single-blog-box inner">
                          <div className="single-blog-thumb">
                            <img src={blog.image} alt="" />
                          </div>
                          <div className="blog-content">
                            <div className="meta-blog">
                              <span>{blog.date}</span>
                              {/* <span>{blog.category}</span> */}
                            </div>
                            <Link to={`/blog-details/${blog.id}`}>
                              {blog.title.slice(0, 50)}...
                            </Link>
                          </div>
                          <div className="blog-button">
                            <Link to={`/blog-details/${blog.id}`}>
                              Read More
                              <span>
                                <i className="bi bi-arrow-right"></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

export default BlogPageArea;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import roomsArray from "../../roomData.js";

const RMDetailsPageArea = () => {
  const [rooms, setRooms] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  const findRoom = rooms && rooms.find((room) => room.id === parseInt(id));

  const carouselOptions = {
    items: 1,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000, // Adjust for a smoother transition
    autoplaySpeed: 1000, // Controls animation speed
    autoplayHoverPause: true,
    nav: true,
    dots: true,
    navText: [
      "<span class='owl-nav-prev'>‹</span>",
      "<span class='owl-nav-next'>›</span>",
    ],
  };

  return (
    <Wrapper>
      <div className="room-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {findRoom && (
                  <OwlCarousel
                    className="room-details-list"
                    {...carouselOptions}
                  >
                    {findRoom && findRoom.detailsImg1 && (
                      <div className="room-detils-thumb">
                        <img
                          src={findRoom && findRoom.detailsImg1}
                          alt="Room 1"
                          height={500}
                        />
                      </div>
                    )}

                    {findRoom && findRoom.detailsImg2 && (
                      <div className="room-detils-thumb">
                        <img
                          src={findRoom && findRoom.detailsImg2}
                          alt="Room 2"
                          height={500}
                        />
                      </div>
                    )}

                    {findRoom && findRoom.detailsImg3 && (
                      <div className="room-detils-thumb">
                        <img
                          src={findRoom && findRoom.detailsImg3}
                          alt="Room 3"
                          height={500}
                        />
                      </div>
                    )}

                    {findRoom && findRoom.detailsImg4 && (
                      <div className="room-detils-thumb">
                        <img
                          src={findRoom && findRoom.detailsImg4}
                          alt="Room 4"
                          height={500}
                        />
                      </div>
                    )}

                    {findRoom && findRoom.detailsImg5 && (
                      <div className="room-detils-thumb">
                        <img
                          src={findRoom && findRoom.detailsImg5}
                          alt="Room 5"
                          height={500}
                        />
                      </div>
                    )}
                  </OwlCarousel>
                )}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="room-details-content">
                    <h4>{findRoom && findRoom.title}</h4>
                    <h1>{findRoom && findRoom.subtitle}</h1>
                    <p
                      className="room-detils-desc"
                      style={{ textAlign: "justify" }}
                    >
                      {findRoom && findRoom.description}
                    </p>

                    {findRoom &&
                      !findRoom.subtitle.includes("Hall") &&
                      !findRoom.subtitle.includes("Cabin") && (
                        <div className="room-details-check-box">
                          <div className="room-details-check-content">
                            <span>
                              <img
                                src="/assets/images/inner/room-details-1.png"
                                alt=""
                              />
                              Check In
                            </span>
                            <p className="check-item">
                              <i className="bi bi-check2"></i>Check-in time{" "}
                              {findRoom && findRoom.checkIn}
                            </p>
                          </div>
                        </div>
                      )}

                    {findRoom &&
                      !findRoom.subtitle.includes("Hall") &&
                      !findRoom.subtitle.includes("Cabin") && (
                        <div className="room-details-check-box upper">
                          <div className="room-details-check-content">
                            <span>
                              <img
                                src="/assets/images/inner/room-details-2.png"
                                alt=""
                              />
                              Check Out
                            </span>
                            <p className="check-item">
                              <i className="bi bi-check2"></i>Check-out time{" "}
                              {findRoom && findRoom.checkOut}
                            </p>
                          </div>
                        </div>
                      )}

                    {/* <h1 className="room-detils-title-2">House Rules</h1>
                    <p className="room-detils-desc upper">
                      {findRoom && findRoom.houseRules}
                    </p> */}

                    {/* <h1 className="room-detils-title-2">
                      Childreen & Extra Beds
                    </h1>
                    <p className="room-detils-desc">
                      {findRoom && findRoom.childrenAndExtraBeds}
                    </p> */}

                    {/* <div className="room-detls-list-item">
                      <ul>
                        {findRoom &&
                          findRoom.listItems.map((list, index) => {
                            return (
                              <li key={index}>
                                <i className="bi bi-check2"></i>
                                {list}
                              </li>
                            );
                          })}
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="booking-list">
                    <div className="booking-list-content">
                      <h4>Booking</h4>
                    </div>
                    <div className="booking-item">
                      <ul>
                        <li>
                          <strong>{findRoom && findRoom.dayType} - </strong>
                          {findRoom && findRoom.price}
                        </li>
                        <li>
                          <strong>Offer - </strong>
                          {findRoom && findRoom.offer}
                        </li>
                        {findRoom &&
                          !findRoom.subtitle.includes("Hall") &&
                          !findRoom.subtitle.includes("Cabin") && (
                            <li>
                              <strong>Check In - </strong>
                              {findRoom && findRoom.checkIn}
                            </li>
                          )}

                        {findRoom &&
                          !findRoom.subtitle.includes("Hall") &&
                          !findRoom.subtitle.includes("Cabin") && (
                            <li>
                              <strong>Check Out - </strong>
                              {findRoom && findRoom.checkOut}
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                  <div className="room-details-amenities">
                    <div className="room-details-amenities-content">
                      <h4>Amenities</h4>
                    </div>
                    <div className="room-amenities-item">
                      <ul>
                        <li>
                          <img
                            src="/assets/images/inner/room-amenities-1.png"
                            alt=""
                          />
                          {findRoom && findRoom.details}
                        </li>
                        <li>
                          <img
                            src="/assets/images/inner/room-amenities-2.png"
                            alt=""
                          />
                          Free WiFi Available
                        </li>

                        {findRoom &&
                          !findRoom.subtitle.includes("Hall") &&
                          !findRoom.subtitle.includes("Cabin") && (
                            <li>
                              <img
                                src="/assets/images/inner/room-amenities-3.png"
                                alt=""
                              />
                              Swimming pool (1 hour) Daily
                            </li>
                          )}

                        {findRoom &&
                          !findRoom.subtitle.includes("Hall") &&
                          !findRoom.subtitle.includes("Cabin") && (
                            <li>
                              <img
                                src="/assets/images/inner/room-amenities-4.png"
                                alt=""
                              />
                              Breakfast
                            </li>
                          )}

                        <li>
                          <img
                            src="/assets/images/inner/room-amenities-5.png"
                            alt=""
                          />
                          250 SQFT Rooms
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: block !important;
  } */
  .room-details .owl-nav-prev,
  .room-details .owl-nav-next {
    font-size: 25px;
    padding: 3px 12px;
    border: 1px solid #fff;
    color: #fff;
  }
  .room-details .owl-carousel .owl-nav button.owl-next,
  .room-details .owl-carousel .owl-nav button.owl-prev {
    padding: 2px 10px !important;
    border-radius: 4px;
  }
  .room_days {
    margin-top: 4px;
  }
  .day_price {
    font-size: 20px;
  }
`;

export default RMDetailsPageArea;

import React from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css"; // Owl Carousel Styles
import "owl.carousel/dist/assets/owl.theme.default.css"; // Owl Carousel Theme Styles
import { Link } from "react-router-dom";

const AboutArea = () => {
  // Owl Carousel options
  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };

  return (
    <Wrapper>
      <div className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <OwlCarousel className="about_list owl-carousel" {...options}>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="/assets/images/about-img/hm-abt-Swimming Pool.png"
                        alt="About Thumbnail 1"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="/assets/images/about-img/hm-abt-eque-cafe.png"
                        alt="About Thumbnail 2"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="/assets/images/about-img/hm-abt-01-09.png"
                        alt="About Thumbnail 3"
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 upper">
              <div className="section-title two">
                {/* <h4>Eque Heritage Hotel & Resort </h4> */}
                <h1>Eque Heritage Hotel & Resort</h1>
                <h1 className="fs-3">Saidpur, Nilphamari</h1>
                <p className="section-desc-2" style={{ textAlign: "justify" }}>
                  Welcome to Eque Heritage Hotel and Resort, your perfect place
                  for peace and luxury, where beautiful nature and amazing
                  service come together. Begin your day with the soft sounds of
                  birds singing in the morning, creating a calm and relaxing
                  vibe. Surrounded by nature’s beauty, our resort is a peaceful
                  getaway from the busy world, giving you a refreshing and
                  relaxing experience in a quiet and serene environment.
                </p>
              </div>
              <div className="about-conuter-box">
                <div className="about-counter-content">
                  <h4 className="counter">38</h4>
                  <span>+</span>
                  <p>Luxury Rooms</p>
                </div>
              </div>
              <div className="about-conuter-box">
                <div className="about-counter-content">
                  <h4 className="counter">4.2</h4>
                  <p>Customer Ratings</p>
                </div>
              </div>
              <div className="animation-bar"></div>
              <div className="luxury-button">
                <Link to="/about">About More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 95%;
    right: 5%;
    width: 100%;
    transform: translateY(-100%);
  }

  .owl-prev,
  .owl-next {
    background-color: #fff !important;
    border: 1px solid #000;
    font-size: 30px !important;
    cursor: pointer;
    color: #000 !important;
    border-radius: 4px !important;
    width: 30px !important;
    height: 30px !important;
  }

  .owl-prev:hover,
  .owl-next:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 768px) {
    .owl-nav {
      top: 115% !important;
    }
  }
`;

export default AboutArea;

import React from "react";
import RoomHeroSection from "./RoomHeroSection";
import RoomBookingArea from "./RoomBookingArea";
import RoomPageArea from "./RoomPageArea";
import RoomFacilitiesArea from "./RoomFacilitiesArea";
import RoomContactArea from "./RoomContactArea";

const Room = () => {
  return (
    <>
      <RoomHeroSection />
      <RoomBookingArea />
      <RoomPageArea />
      <RoomFacilitiesArea />
      <RoomContactArea />
    </>
  );
};

export default Room;

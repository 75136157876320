import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import serviceDataArray from "../../serviceData.js";

const ServicePageArea = () => {
  const [service, setservice] = useState([]);
  const [activeTab, setActiveTab] = useState("Unpaid");

  useEffect(() => {
    setservice(serviceDataArray);
  }, []);

  const uniqueCategories = Array.from(
    new Set(serviceDataArray.map((item) => item.category))
  );

  const tabs = [...uniqueCategories];

  // Handler to update active tab
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Filter the food items based on the active tab
  const filteredService = service.filter(
    (item) => item.category.toLowerCase() === activeTab.toLowerCase()
  );

  return (
    <Wrapper>
      <div className="facilities-area">
        <div className="container">
          <div
            className="food-menu-area py-2 my-4 rounded"
            style={{ backgroundColor: "#C19D68" }}
          >
            <div className="container">
              <div className="row align-items-center p-3">
                <div className="col-lg-6 col-md-12">
                  <div className="section-title two mb-4">
                    <h4 className="text-dark">Resort's Pricing</h4>
                    <h1 className="text-white">Enjoy Complete & Best</h1>
                    <h1 className="text-white">Quality Services</h1>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="luxury-button">
                    <Link to="/pricing">View Pricing</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img src="/assets/images/home-1/section-shape111.png" alt="" />
                </div>
                <h1>Resort's Services</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>

          <div className="food-menu-area py-0 mt-0 rounded">
            <div className="container pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="food-tab-menu d-flex justify-content-center align-items-center">
                    <ul
                      className="nav nav-pills mb-3 d-flex justify-content-left align-items-center gap-2 uull row_horizon"
                      role="tablist"
                    >
                      {tabs.map((tab) => (
                        <li key={tab} className="nav-item" role="presentation">
                          <button
                            type="button"
                            className={`nav-link ${
                              activeTab === tab ? "active" : ""
                            }`}
                            onClick={() => handleTabChange(tab)}
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {tab}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade show active">
              {filteredService &&
                filteredService.map((service, index) => {
                  return (
                    <div
                      key={service.id}
                      className={`row add-boder d-flex ${
                        index % 2 === 0 ? "" : "flex-row-reverse"
                      }`}
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="single-facilities-images-box">
                          <div className="facilities-thumb">
                            <img
                              src={service.image}
                              alt=""
                              // style={{ width: "630px", height: "370px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div
                          className={`single-facilities-box ${
                            index % 2 === 0 ? "" : "two"
                          } `}
                        >
                          <div className="facilities-content">
                            {/* <h4>{service.subtitle}</h4> */}
                            <h1>{service.title}</h1>
                            <p style={{ textAlign: "justify" }}>
                              {service.description}
                            </p>
                            <Link
                              className="facilities-button"
                              to={`/services-details/${service.id}`}
                            >
                              <i className="bi bi-arrow-right"></i>
                            </Link>
                          </div>
                          <div
                            className={`facilities-number ${
                              index % 2 === 0 ? "" : "two"
                            }`}
                          >
                            <h1>{service.id}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* .facilities-area {
    padding: 109px 0 87px;
  } */

  .food-menu-area {
    /* padding: 122px 0 102px; */
    /* padding: 0px 0 102px !important; */
  }

  .row_horizon {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    -webkit-overflow-scrolling: touch;
  }

  .uull {
    padding-left: 0 !important;
  }
  .uull li {
    margin-bottom: 7px;
  }

  .row_horizon::-webkit-scrollbar {
    height: 5px;
  }

  .row_horizon::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .row_horizon::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .row_horizon::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default ServicePageArea;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };
  return (
    <Wrapper>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            {/* Contact Section */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget-contact">
                <div className="footer-widget-logo">
                  <Link to="/">
                    <img
                      src="/assets/images/logo/Eque logo (4).png"
                      alt="Logo"
                      style={{ width: "174px", height: "62px" }}
                    />
                  </Link>
                </div>
                <div className="footer-widget-content">
                  <div className="footer-widget-title">
                    <h4>Contact Info</h4>
                  </div>
                  <div className="footer-widget-contact-info">
                    <ul>
                      <li>
                        <i className="bi bi-telephone-fill"></i>
                        <Link to="tel:+8801406767000">+8801406767000</Link>
                      </li>
                      <li>
                        <i className="bi bi-whatsapp"></i>
                        <Link target="_blank" to="https://wa.me/+8801317121871">
                          +8801317121871
                        </Link>
                      </li>
                      <li>
                        <i className="bi bi-envelope"></i>
                        <Link to="mailto:equeheritageresort@gmail.com">
                          equeheritageresort@gmail.com
                        </Link>
                      </li>
                      <li>
                        <i className="bi bi-geo-alt-fill"></i>
                        <Link
                          target="_blank"
                          to="https://www.google.com/maps/place/Eque+Heritage+Hotel+and+Resort,+Saidpur,+Nilphamari./@25.77336,88.902151,14z/data=!4m9!3m8!1s0x39e3517fbcb84f95:0xaf6c721b53293947!5m2!4m1!1i2!8m2!3d25.7733596!4d88.9021511!16s%2Fg%2F11h84b4_wd?hl=en&entry=ttu&g_ep=EgoyMDI1MDEwMi4wIKXMDSoASAFQAw%3D%3D"
                        >
                          Airport Road, Near By Fivestar Play Ground,
                          Saidpur-5310, NilPhamari, Bangladesh
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footer-widget-social-icon">
                  <ul>
                    <li>
                      <Link
                        to="https://www.facebook.com/Eque.Resort/?locale=bg_BG"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.booking.com/hotel/bd/eque-heritage-amp-resort.en-gb.html"
                        target="_blank"
                      >
                        {/* <i className="fab fa-twitter"></i> */}
                        <i className="fas fa-calendar-check"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.tripadvisor.com/Hotel_Review-g2214125-d23832328-Reviews-Eque_Heritage_Hotel_Resort-Saidpur_Rangpur_Division.html"
                        target="_blank"
                      >
                        {/* <i className="fab fa-linkedin-in"></i> */}
                        <i className="fab fa-tripadvisor"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Useful Links */}
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-widget-content upper">
                <div className="footer-widget-title">
                  <h4>Useful Links</h4>
                </div>
                <div className="footer-widget-menu">
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/room">Accommodation</Link>
                    </li>
                    <li>
                      <Link to="/restaurant">Restaurant</Link>
                    </li>
                    <li>
                      <Link to="/cafe">Cafe</Link>
                    </li>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Gallery Section */}
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="footer-widget-content">
              <div className="footer-widget-title">
                <h4>Gallery</h4>
              </div>
              <div className="footer-widget-gallery">
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  to="/assets/images/home-1/gallery-1.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-1.jpg"
                    alt="Gallery 1"
                  />
                </a>
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  href="/assets/images/home-1/gallery-2.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-2.jpg"
                    alt="Gallery 2"
                  />
                </a>
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  href="/assets/images/home-1/gallery-3.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-3.jpg"
                    alt="Gallery 3"
                  />
                </a>
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  href="/assets/images/home-1/gallery-4.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-4.jpg"
                    alt="Gallery 4"
                  />
                </a>
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  href="/assets/images/home-1/gallery-5.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-5.jpg"
                    alt="Gallery 5"
                  />
                </a>
                <a
                  className="place venobox vbox-item"
                  data-gall="lace-imgs"
                  href="/assets/images/home-1/gallery-6.jpg"
                >
                  <img
                    src="/assets/images/home-1/gallery-6.jpg"
                    alt="Gallery 6"
                  />
                </a>
              </div>
            </div>
          </div> */}

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-widget-content">
                <div className="footer-widget-title">
                  <h4>Gallery</h4>
                </div>
                <div className="footer-widget-gallery">
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      // openModal("/assets/images/home-1/gallery-1.jpg")
                      openModal("/assets/images/gallery/1 Royal Suite (3).jpg")
                    }
                  >
                    <img
                      // src="/assets/images/home-1/gallery-1.jpg"
                      src="/assets/images/gallery/1 Royal Suite (3).jpg"
                      alt="Gallery 1"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      openModal(
                        "/assets/images/gallery/2 Couple Deluxe (10).jpg"
                      )
                    }
                  >
                    <img
                      src="/assets/images/gallery/2 Couple Deluxe (10).jpg"
                      alt="Gallery 2"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      openModal(
                        "/assets/images/gallery/3 Eque-Family Suite (8).jpg"
                      )
                    }
                  >
                    <img
                      src="/assets/images/gallery/3 Eque-Family Suite (8).jpg"
                      alt="Gallery 3"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      openModal("/assets/images/gallery/4 Twine Deluxe (8).jpg")
                    }
                  >
                    <img
                      src="/assets/images/gallery/4 Twine Deluxe (8).jpg"
                      alt="Gallery 4"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      openModal("/assets/images/gallery/5 single delux (5).jpg")
                    }
                  >
                    <img
                      src="/assets/images/gallery/5 single delux (5).jpg"
                      alt="Gallery 5"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                  <Link
                    className="place"
                    to="#"
                    onClick={() =>
                      openModal(
                        "/assets/images/gallery/6 Eque Single Deluxe (4).jpg"
                      )
                    }
                  >
                    <img
                      src="/assets/images/gallery/6 Eque Single Deluxe (4).jpg"
                      alt="Gallery 6"
                      style={{ width: "93px", height: "93px" }}
                    />
                  </Link>
                </div>
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                  <div
                    className="modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="modal-image"
                    />
                    <button className="close-modal" onClick={closeModal}>
                      &times;
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Newsletter Section */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-widget-content">
                <div className="footer-widget-title">
                  <h4>Newsletter</h4>
                </div>
                <p>Subscribe to our Newsletter</p>
                <form action="#">
                  <div className="single-newsletter-box">
                    <input
                      type="email"
                      name="Email"
                      placeholder="Enter E-Mail"
                      required
                    />
                    <button type="submit">Subscribe Now</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  li a {
    text-decoration: none;
    color: #acacac !important;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    position: relative;
    border: none;
    background-color: transparent;
  }

  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
  }

  .close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    z-index: 1100;
    line-height: 1;
  }
`;

export default Footer;

const heroSlides = [
  // {
  //   id: 1,
  //   rating: 3,
  //   title: "Welcome to Eque Heritage Hotel & Resort",
  //   subtitle: "Feel The Nature",
  //   subtitleOther: "The Best Eque Heritage Hotel & Resort",
  //   location: "Saidpur, Nilphamari",
  //   link: "/about",
  //   contact: "+8801317121871",
  //   contactLink: "tel:+8801317121871",
  //   img: "/assets/images/banner-img/banner-02.jpg",
  //   style: "hero-area", // Default style
  // },

  // {
  //   id: 2,
  //   rating: 3,
  //   title: "Welcome to Eque Heritage Hotel & Resort",
  //   subtitle: "Feel The Nature",
  //   location: "Saidpur, Nilphamari",
  //   link: "/about",
  //   contact: "+8801317121871",
  //   contactLink: "tel:+8801317121871",
  //   img: "/assets/images/banner-img/banner-03.jpg",
  //   style: "hero-area style-three",
  // },
  // {
  //   id: 3,
  //   rating: 3,
  //   title: "Welcome to Eque Heritage Hotel & Resort",
  //   subtitle: "Feel The Nature",
  //   location: "Saidpur, Nilphamari",
  //   link: "/about",
  //   contact: "+8801317121871",
  //   contactLink: "tel:+8801317121871",
  //   img: "/assets/images/banner-img/banner-04.jpg",
  //   style: "hero-area style-four",
  // },
  {
    id: 4,
    rating: 3,
    title: "Welcome to Eque Heritage Hotel & Resort",
    subtitle: "Feel The Nature",
    location: "Saidpur, Nilphamari",
    link: "/about",
    contact: "+8801317121871",
    contactLink: "tel:+8801317121871",
    img: "/assets/images/banner-img/banner-05.jpg",
    style: "hero-area style-five",
  },
  {
    id: 5,
    rating: 3,
    title: "Welcome to Eque Heritage Hotel & Resort",
    subtitle: "Feel The Nature",
    location: "Saidpur, Nilphamari",
    link: "/about",
    contact: "+8801317121871",
    contactLink: "tel:+8801317121871",
    img: "/assets/images/banner-img/banner-06.jpg",
    style: "hero-area style-six",
  },
  // {
  //   id: 6,
  //   rating: 3,
  //   title: "Welcome to Eque Heritage Hotel & Resort",
  //   subtitle: "Feel The Nature",
  //   location: "Saidpur, Nilphamari",
  //   link: "/about",
  //   contact: "+8801317121871",
  //   contactLink: "tel:+8801317121871",
  //   img: "/assets/images/banner-img/banner-01.jpg",
  //   style: "hero-area style-two",
  // },
];

export default heroSlides;

import React from "react";
import ResturantHeroSection from "./ResturantHeroSection";
import ResturantFoodMenuArea from "./ResturantFoodMenuArea";

const Resturant = () => {
  return (
    <>
      <ResturantHeroSection />
      <ResturantFoodMenuArea />
    </>
  );
};

export default Resturant;

const testimonialData = [
  {
    id: 1,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      " Excellent location close to the airport and local bazaar. Room service is excellent and the food ordered is very good and not expensive. Quantities are pretty big. Room was made up and toilets cleaned perfectly",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Private",
    authorRole: "Manager",
  },
  {
    id: 2,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      "Overall I can say that I cannot expect such a wonderful place in saidpur. Such a luxurious Palace. I'm very overwhelmed with their service.",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Sajjad Mahmud",
    authorRole: "Manager",
  },
  {
    id: 3,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      "I have visited several times here. I always prefer to stay over night here. Very peaceful, calm & quite environment. Quality service and delicious food availability. Fantastic resort service I avail even it is far away from the capital city Dhaka. Anyway, it is very close to the airport. Have online booking and car pick up & drooping facility.",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Private",
    authorRole: "Manager",
  },
  {
    id: 4,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      "One of the finest resort in Saidpur of Nilphamari district. They avail best customer service and cleanliness. If you’re looking for a high class accommodation and food in Saidpur, this place could be your right option. It's kinda costly outfit here, so it does take a huge toll on your wallet. It's a three star hotel, having a swimming pool, resort and garden in it’s premises. Their food quality is okay type, need to focus a bit on this section. I will recommend this place for better decoration, services and it’s wide area. Good luck to all tourists out there.",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Md. Raihan Hossain",
    authorRole: "Manager",
  },
  {
    id: 5,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      "Eco Resort is one of the hotels in Syedpur Upazila.  It is an international standard hotel.  There is a huge conversation hall.  Restaurant and designated car parking space.  A large waiting room for receiving guests.  The ambience of this resort is enchanting.  The place is very safe and quiet.  Definitely stay here.",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Kawsar Bin Rifat",
    authorRole: "Manager",
  },
  {
    id: 6,
    quoteIcon: "/assets/images/home-1/testi-quote.png",
    rating: 4.5,
    content:
      "Holiday | Friends Great location, good food, aesthetically pleasing environment. Almost everything about this resort is good.I want more places like this around Rangpur district.",
    authorImage: "/assets/images/home-1/users.jpg",
    authorName: "Sharafat Ahmed",
    authorRole: "Manager",
  },
];

export default testimonialData;

import React from "react";
import RmDetailsHeroArea from "./RmDetailsHeroArea";
import RMDetailsPageArea from "./RMDetailsPageArea";

const RoomDetails = () => {
  return (
    <>
      <RmDetailsHeroArea />
      <RMDetailsPageArea />
    </>
  );
};

export default RoomDetails;

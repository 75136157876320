const roomData = [
  {
    id: 1,
    img: "/assets/images/room/1 Royal Suite (3).jpg",
    detailsImg1: "/assets/images/room-details/Royal Suite.jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    price: "৳ 12,500",
    title: "Luxury Room",
    subtitle: "Royal Suite",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "1 couple Bed 2 Person",
    rating: 4.5,
    offer: "25%",
    dayType: "Per Night",

    description: `Eque Heritage Hotel and Resort in Saidpur (Nilphamari), is a top 3-star choice for those who want a mix of style and comfort. The Royal Suite is the height of luxury, with stylish decorations, high-quality furniture, and a peaceful vibe that matches the beautiful natural surroundings. Guests can relax in a large living area and use a modern bathroom, all while enjoying the hotel's historic charm and peaceful scenery. Additionally, each room is equipped with a Hot & Cold AC System, ensuring a comfortable stay regardless of the weather. It’s an ideal spot for a relaxing and luxurious getaway.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 2,
    img: "/assets/images/room/2 Couple Deluxe (10).jpg",
    detailsImg1: "/assets/images/room-details/Couple Deluxe.jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    price: "৳ 6,500",
    title: "Luxury Room",
    subtitle: "Couple Deluxe",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "1 Bed 1 or 2 Person",
    rating: 5,
    offer: "25%",
    dayType: "Per Night",

    description: `The Deluxe couple room is designed with a modern style, making it a warm space for couples. It has a large bathroom with two sinks with hot & cold water, a separate shower, and a big bathtub, offering a luxurious experience. The suite comes with one flat-screen TV, free Wi-Fi, one wardrobe for your things, and a dressing table for cordial moments. This comfortable and well-equipped room is perfect for couples looking for a romantic escape.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 3,
    img: "/assets/images/room/3 Eque-Family Suite (8).jpg",
    detailsImg1: "/assets/images/room-details/3 Eque-Family Suite (08).jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    detailsImg4: "/assets/images/room-details/3 Eque-Family Suite (09).jpg",
    price: "৳ 5,500",
    title: "Luxury Room",
    subtitle: "Eque-Family Suite",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "2 Bed 3 Person",
    rating: 4,
    offer: "25%",
    dayType: "Per Night",

    description: `Comfort and elegance come together in our Eque-Family Suite. The suite is perfect for up to three people. This large suite has two beds—a king-size bed and a single bed—so everyone can sleep well. This is ideal for couples, friends, travellers, and children. You’ll find a nice bathroom with Geyser, two flat-screen TVs for watching shows, and free Wi-Fi to stay online. The suite also has two wardrobes for your clothes, plus a tea table and a dressing table, making it a great place to relax and spend time together. Enjoy a fun and relaxing time in a lovely setting, creating special moments you'll always remember.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 4,
    img: "/assets/images/room/4 Twine Deluxe (8).jpg",
    detailsImg1: "/assets/images/room-details/Twin Deluxe-1.jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    detailsImg4: "/assets/images/room-details/Twin Deluxe-2.jpg",
    price: "৳ 4,500",
    title: "Luxury Room",
    subtitle: "Twine Deluxe",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "2 Bed 2 Person",
    rating: 3.5,
    offer: "25%",
    dayType: "Per Night",

    description: `The Twin Deluxe Room at Eque Heritage Hotel and Resort is a large, modern space, ideal for two people. It has two comfy beds, a warm and inviting atmosphere, and a storage unit for your garments and shoes. This stylish room includes everything you need for a relaxing stay. It has a bathroom with modern features, a tea table for spending quality time, and free Wi-Fi to keep you connected with family and friends. All of this ensures a comfortable and unforgettable experience in the peaceful setting of the hotel.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 5,
    img: "/assets/images/room/5 single delux (5).jpg",
    detailsImg1: "/assets/images/room-details/5 single-deluxe (05).jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    price: "৳ 3,500",
    title: "Luxury Room",
    subtitle: "Single-Deluxe",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "1 Bed 1 Person",
    rating: 3,
    offer: "25%",
    dayType: "Per Night",

    description: `This Single Deluxe room at Eque Heritage Hotel and Resort is ideal for solo people. It has everything you need for a relaxing stay. The room includes a private bathroom with hot & cold water and modern fittings, creating a home-like atmosphere. There’s a wardrobe with plenty of space for your clothes, and you stay connected with wireless high-speed internet. At the end of the day, enjoy your favorite shows on the flat-screen TV.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 6,
    img: "/assets/images/room/6 Eque Single Deluxe (4).jpg",
    detailsImg1: "/assets/images/room-details/Eque Single Deluxe.jpg",
    // detailsImg2: "/assets/images/room-details/room-details-1.jpg",
    // detailsImg3: "/assets/images/room-details/room-details-2.jpg",
    price: "৳ 4,500",
    title: "Luxury Room",
    subtitle: "Eque Single Deluxe",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "1 Bed 1 Person (3rd Floor)",
    rating: 3.5,
    offer: "25%",
    dayType: "Per Night",

    description: `This Eque Single Deluxe room at Eque Heritage Hotel and Resort is ideal for solo people. It has everything you need for a relaxing stay. The room includes a private bathroom with hot & cold water and modern fittings, creating a home-like atmosphere. There’s a wardrobe with plenty of space for your clothes, and you stay connected with wireless high-speed internet. At the end of the day, enjoy your favorite shows on the flat-screen TV.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 7,
    img: "/assets/images/room/7 Eque Hall Room (1).jpg",
    detailsImg1: "/assets/images/room-details/Eque Hall Room-1.jpg",
    detailsImg2: "/assets/images/room-details/Eque Hall Room-2.jpg",
    detailsImg3: "/assets/images/room-details/Eque Hall Room-3.jpg",
    price: "৳ 30,000",
    title: "Budget Room",
    subtitle: "Eque Hall Room",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "N/A",
    rating: 4,
    offer: "25%",
    dayType: "Half Day",

    description: `Eque Heritage Hotel and Resort is the perfect place for your next conference, meeting, or family event like a wedding ceremony or anniversary. Featuring a pillar-less hall with a large event space of 3,500 square feet, the resort offers a stylish setting with modern technology to make sure everything runs smoothly. Whether you want to connect with vendors and clients in a professional space or celebrate special moments with family and friends, Eque Heritage Hotel and Resort has flexible areas to meet all your needs. You’ll also enjoy top-notch service and a welcoming atmosphere that you’ll always remember.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 8,
    img: "/assets/images/room/7 Eque Hall Room (1).jpg",
    detailsImg1: "/assets/images/room-details/Eque Hall Room-1.jpg",
    detailsImg2: "/assets/images/room-details/Eque Hall Room-2.jpg",
    detailsImg3: "/assets/images/room-details/Eque Hall Room-3.jpg",
    price: "৳ 50,000",
    title: "Budget Room",
    subtitle: "Eque Hall Room",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "N/A",
    rating: 4.5,
    offer: "25%",
    dayType: "Full Day",

    description: `Eque Heritage Hotel and Resort is the perfect place for your conference, a meeting with clients or employees, or a happy family gathering like a birthday or anniversary. With a large space of 1100 sqft, the hotel provides a beautifully designed setting that can host all kinds of events. Our venue has modern technology to make sure your event goes smoothly. Enjoy great service, a welcoming atmosphere where every moment becomes unforgettable.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 9,
    img: "/assets/images/room/9 cabin.jpg",
    detailsImg1: "/assets/images/room-details/cabin-details.jpg",
    price: "৳ 2500",
    title: "Budget Room",
    subtitle: "Cabin",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "N/A",
    rating: 4,
    offer: "25%",
    dayType: "Per Hour",

    description: `Eque Heritage Hotel and Resort provides a perfect place for your business meetings and conferences, blending style with modern technology. Our advanced meeting room is designed to help you work efficiently, with comfortable seating for up to 25 people and a classy look with elegant decorations. Our helpful staff is always ready to assist you. At Eque Heritage Hotel and Resort, you can boost your company’s image and enjoy a mix of luxury and practicality for unforgettable meetings and events.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  {
    id: 10,
    img: "/assets/images/room/mini hall room.jpg",
    detailsImg1: "/assets/images/room/mini hall room.jpg",
    price: "৳ 6000",
    title: "Budget Room",
    subtitle: "Mini Hall Room",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "N/A",
    rating: 4,
    offer: "25%",
    dayType: "Half Day",

    description: `Eque Heritage Hotel and Resort provides a perfect place for your business meetings and conferences, blending style with modern technology. Our advanced meeting room is designed to help you work efficiently, with comfortable seating for up to 25 people and a classy look with elegant decorations. Our helpful staff is always ready to assist you. At Eque Heritage Hotel and Resort, you can boost your company’s image and enjoy a mix of luxury and practicality for unforgettable meetings and events.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },

  {
    id: 11,
    img: "/assets/images/room/mini hall room.jpg",
    detailsImg1: "/assets/images/room/mini hall room.jpg",
    price: "৳ 12000",
    title: "Budget Room",
    subtitle: "Mini Hall Room",
    checkIn: "2:00 PM",
    checkOut: "12:00 PM",
    details: "N/A",
    rating: 4,
    offer: "25%",
    dayType: "Full Day",

    description: `Eque Heritage Hotel and Resort provides a perfect place for your business meetings and conferences, blending style with modern technology. Our advanced meeting room is designed to help you work efficiently, with comfortable seating for up to 25 people and a classy look with elegant decorations. Our helpful staff is always ready to assist you. At Eque Heritage Hotel and Resort, you can boost your company’s image and enjoy a mix of luxury and practicality for unforgettable meetings and events.`,
    houseRules:
      "Professionally deliver fully researched scenarios with turnkey communities.",
    childrenAndExtraBeds:
      "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
    listItems: [
      "Quickly generate bricks-and-clicks",
      "Interactively cultivate visionary platforms",
      "Energistically envisioneer resource",
      "Uniquely restore turnkey paradigms",
    ],
  },
  // {
  //   id: 11,
  //   img: "/assets/images/home-1/room-5.jpg",
  //   halfDayPrice: "৳ 2500",
  //   title: "Budget Room",
  //   subtitle: "Sound System",
  //   checkIn: "2:00 PM",
  //   checkOut: "12:00 PM",
  //   details: "N/A",
  //   rating: 3.5,
  //   offer: "25%",
  //   link: "/room-details",
  //   description: `Rapidiously myocardinate cross-platform intellectual
  //                   capital after marketing model. Appropriately create
  //                   interactive infrastructures after maintainable are
  //                   Holisticly facilitate stand-alone inframe extend state of
  //                   the art benefits via web-enabled value. Completely
  //                   fabricate extensible infomediaries rather than reliable
  //                   e-services. Dramatically whiteboard alternative`,
  //   houseRules:
  //     "Professionally deliver fully researched scenarios with turnkey communities.",
  //   childrenAndExtraBeds:
  //     "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
  //   listItems: [
  //     "Quickly generate bricks-and-clicks",
  //     "Interactively cultivate visionary platforms",
  //     "Energistically envisioneer resource",
  //     "Uniquely restore turnkey paradigms",
  //   ],
  // },
  // {
  //   id: 12,
  //   img: "/assets/images/home-1/room-5.jpg",
  //   halfDayPrice: "৳ 10,000",
  //   title: "Budget Room",
  //   subtitle: "Conference Room (Pax-50)",
  //   checkIn: "2:00 PM",
  //   checkOut: "12:00 PM",
  //   details: "N/A",
  //   rating: 4.5,
  //   offer: "25%",
  //   link: "/room-details",
  //   description: `Rapidiously myocardinate cross-platform intellectual
  //                   capital after marketing model. Appropriately create
  //                   interactive infrastructures after maintainable are
  //                   Holisticly facilitate stand-alone inframe extend state of
  //                   the art benefits via web-enabled value. Completely
  //                   fabricate extensible infomediaries rather than reliable
  //                   e-services. Dramatically whiteboard alternative`,
  //   houseRules:
  //     "Professionally deliver fully researched scenarios with turnkey communities.",
  //   childrenAndExtraBeds:
  //     "Applications without seamless data. Uniquely underwhelm quality outsourcing before transparent relationships.",
  //   listItems: [
  //     "Quickly generate bricks-and-clicks",
  //     "Interactively cultivate visionary platforms",
  //     "Energistically envisioneer resource",
  //     "Uniquely restore turnkey paradigms",
  //   ],
  // },
];
export default roomData;

import React from "react";
import AboutHeroSection from "./AboutHeroSection";
import AboutPage from "./AboutPage";
import AboutFeatureArea from "./AboutFeatureArea";
import AboutManagerCallDo from "./AboutManagerCallDo";
// import AboutTeamArea from "./AboutTeamArea";
// import AboutTestimonailArea from "./AboutTestimonailArea";

const About = () => {
  return (
    <>
      <AboutHeroSection />
      <AboutPage />
      <AboutFeatureArea />
      <AboutManagerCallDo />
      {/* <AboutTeamArea /> */}
      {/* <AboutTestimonailArea /> */}
    </>
  );
};

export default About;

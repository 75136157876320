import React, { useState } from "react";
import styled from "styled-components";
import { FiPlus, FiX } from "react-icons/fi";

const PricingFaqsArea = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqsData = [
    {
      question: "How to Booking a Room?",
      answer:
        "Credibly morph resource maximizing applications rather than fully tested metrics via intermandated expertise. Globally administrate reliable platforms and seamlessly brand systems.",
    },
    {
      question: "What kinds of Bedroom available?",
      answer:
        "Credibly morph resource maximizing applications rather than fully tested metrics via intermandated expertise. Globally administrate reliable platforms and seamlessly brand systems.",
    },
    {
      question: "Do you have any Discount Current Month?",
      answer:
        "Credibly morph resource maximizing applications rather than fully tested metrics via intermandated expertise. Globally administrate reliable platforms and seamlessly brand systems.",
    },
    {
      question: "Have you available money back Guarantee?",
      answer:
        "Credibly morph resource maximizing applications rather than fully tested metrics via intermandated expertise. Globally administrate reliable platforms and seamlessly brand systems.",
    },
  ];

  return (
    <Wrapper>
      <div className="faqs-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img
                    src="/assets/images/home-1/section-shape1.png"
                    alt="Section Shape"
                  />
                </div>
                <h1>Frequently Asked Questions</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {faqsData.map((faq, index) => (
                <div
                  className={`faqs ${activeIndex === index ? "active" : ""}`}
                  key={index}
                >
                  <div
                    className="questionn"
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.question}
                    <span className="icon">
                      {activeIndex === index ? <FiX /> : <FiPlus />}
                    </span>
                  </div>
                  <div
                    className="answer"
                    style={{
                      maxHeight: activeIndex === index ? "200px" : "0",
                      overflow: "hidden",
                      transition: "max-height 0.3s ease",
                      padding: activeIndex === index ? "10px" : "0",
                    }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .faqs {
    margin-bottom: 15px;
    border: 1px solid #c19d68;
    border-radius: 5px;
    overflow: hidden;
  }
  .faqs .questionn {
    background: #c19d68;
    padding: 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
  }
  .faqs .questionn .icon {
    font-size: 18px;
  }
  .faqs .answer {
    background: #292929;
    color: #fff;
    padding: 0;
    transition: all 0.3s ease;
  }
  .faqs.active .answer {
    padding: 10px 15px;
  }
`;

export default PricingFaqsArea;

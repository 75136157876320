import React from "react";
import ServiceHeroArea from "./ServiceHeroArea";
import ServicePageArea from "./ServicePageArea";

const Service = () => {
  return (
    <>
      <ServiceHeroArea />
      <ServicePageArea/>
    </>
  );
};

export default Service;

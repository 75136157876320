import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import roomsArray from "../../roomData.js";

const RoomPageArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <li key={`full-${i}`}>
          <i className="bi bi-star-fill"></i>
        </li>
      );
    }
    if (halfStar) {
      stars.push(
        <li key="half">
          <i className="bi bi-star-half"></i>
        </li>
      );
    }
    return stars;
  };

  return (
    <Wrapper>
      <div className="room-area inner" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img src="/assets/images/home-1/section-shape111.png" alt="" />
                </div>
                <h1>Resort's Rooms & Suites</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            {rooms &&
              rooms.map((room) => {
                return (
                  <div key={room.id} className="col-lg-4 col-md-6">
                    <div className="room-single-box">
                      <div className="room-thumb">
                        <img src={room.img} alt="" />
                        <div className="room-details-button">
                          <Link to={`/room-details/${room.id}`}>
                            View Details<i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="room-pricing">
                        <span className="dolar text-white">{room.price}</span>
                        <span>{room.dayType}</span>
                      </div>

                      <div className="room-content">
                        <h4>{room.title}</h4>
                        <Link to={`/room-details/${room.id}`}>
                          {room.subtitle}
                        </Link>
                        <div className="d-flex justify-content-between align-items-center">
                          {room &&
                          !room.subtitle.includes("Hall") &&
                          !room.subtitle.includes("Cabin") ? (
                            <p>Check in - {room.checkIn}</p>
                          ) : (
                            <p>No Check in </p>
                          )}

                          {room &&
                          !room.subtitle.includes("Hall") &&
                          !room.subtitle.includes("Cabin") ? (
                            <p>Check out - {room.checkOut}</p>
                          ) : (
                            <p>No Check out</p>
                          )}
                        </div>
                      </div>
                      <div className="room-bottom">
                        <div className="room-bottom-icon">
                          <span>
                            <img
                              src="/assets/images/home-1/room-bottom-icon.png"
                              alt=""
                            />
                            {room.details}
                          </span>
                        </div>
                        <div className="coustomar-rating">
                          <ul>{renderStars(room.rating)}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .luxury-button:before {
    height: 0px !important;
  }
`;

export default RoomPageArea;

import React from "react";
import ContactHeroSection from "./ContactHeroSection";
import ContactPage from "./ContactPage";
import MapArea from "./MapArea";

const Contact = () => {
  return (
    <>
      <ContactHeroSection />
      <ContactPage />
      <MapArea />
    </>
  );
};

export default Contact;

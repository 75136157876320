import React, { useEffect, useState } from "react";
import styled from "styled-components";
import roomsArray from "../../roomData.js";

const RoomBookingArea = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms(roomsArray);
  }, []);

  const qunantityArray = [
    { id: 1, quantity: 1 },
    { id: 2, quantity: 2 },
    { id: 3, quantity: 3 },
    { id: 4, quantity: 4 },
    { id: 5, quantity: 5 },
  ];

  return (
    <Wrapper>
      <div className="find-room-area mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <h1>Check Availibity</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <form
              action="https://formspree.io/f/myyleorq"
              method="POST"
              id="dreamit-form"
            > */}
            <div className="row add-bg align-items-center">
              <div className="booking-input-box">
                <h4>Check In</h4>
                <input type="date" required />
              </div>
              <div className="booking-input-box">
                <h4>Check Out</h4>
                <input type="date" required />
              </div>

              <div className="booking-input-box">
                <h4>Rooms</h4>
                <select name="rooms" id="rooms">
                  <option value="">Select Room</option>
                  {rooms &&
                    rooms.map((item) => {
                      return (
                        <option key={item.id} value={item.subtitle}>
                          {item.subtitle}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="booking-input-box upper">
                <h4>Guests</h4>
                <select name="place" id="place">
                  <option value="saab">01 Adult, 0 Child</option>
                  <option value="saab">02 Adult, 0 Child</option>
                  <option value="opel">02 Adult, 1 Child</option>
                  <option value="audi">02 Adult, 2 Child</option>
                  <option value="audi">02 Adult, 3 Child</option>
                </select>
                {/* <select name="guests" id="guests">
                  <option value="">Select Guest</option>
                  {rooms &&
                    rooms.map((item) => {
                      return (
                        <option key={item.id} value={item.details}>
                          {item.details}
                        </option>
                      );
                    })}
                </select> */}
              </div>

              <div className="booking-input-box upper">
                <h4>Quantity</h4>
                <select name="quantity" id="quantity">
                  <option value="">Select Quantity</option>
                  {qunantityArray &&
                    qunantityArray.map((item) => {
                      return (
                        <option key={item.id} value={item.quantity}>
                          {item.quantity}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="booking-button">
                <button type="submit">Book Now</button>
              </div>
            </div>
            {/* </form> */}
            <div id="status"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  @media screen and (max-width: 1024px) {
    .booking-input-box {
      position: relative;
      width: 17%;
      display: inline-block;
    }
  }
  @media screen and (max-width: 768px) {
    .booking-input-box {
      width: 20%;
    }
    .booking-button {
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 425px) {
    .booking-input-box {
      width: 100%;
    }
    .booking-button {
      margin-top: 8px;
    }
    .main_content {
      display: flex;
      flex-direction: column !important;
    }
  }
  /* .modal-content {
    margin-top: 1135px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  } */
`;

export default RoomBookingArea;

const galleryImages = [
  {
    id: 1,
    image: "/assets/images/gallery/1 Royal Suite (3).jpg",
    alt: "Royal Suite",
  },
  {
    id: 2,
    image: "/assets/images/gallery/2 Couple Deluxe (10).jpg",
    alt: "Couple Deluxe",
  },
  {
    id: 3,
    image: "/assets/images/gallery/3 Eque-Family Suite (8).jpg",
    alt: "Eque-Family Suite",
  },
  {
    id: 4,
    image: "/assets/images/gallery/4 Twine Deluxe (8).jpg",
    alt: "Twine Deluxe",
  },
  {
    id: 5,
    image: "/assets/images/gallery/5 single delux (5).jpg",
    alt: "Single Deluxe",
  },
  {
    id: 6,
    image: "/assets/images/gallery/6 Eque Single Deluxe (4).jpg",
    alt: "Eque Single Deluxe",
  },
  {
    id: 7,
    image: "/assets/images/gallery/7 Eque Hall Room (1).jpg",
    alt: "Eque Hall Room",
  },
  {
    id: 8,
    image: "/assets/images/gallery/mini hall room.jpg",
    alt: "Mini Hall Room",
  },
  {
    id: 9,
    image: "/assets/images/gallery/cabin.jpg",
    alt: "Cabin",
  },
  {
    id: 10,
    image: "/assets/images/gallery/gallery-image-1.jpg",
    alt: "image",
  },
  {
    id: 12,
    image: "/assets/images/gallery/gallery-image-2.png",
    alt: "image",
  },
  {
    id: 13,
    image: "/assets/images/gallery/gallery-image-3.png",
    alt: "image",
  },
  {
    id: 14,
    image: "/assets/images/gallery/gallery-image-4.jpg",
    alt: "image",
  },
  {
    id: 15,
    image: "/assets/images/gallery/gallery-image-5.jpg",
    alt: "image",
  },
  {
    id: 16,
    image: "/assets/images/gallery/gallery-image-6.jpg",
    alt: "image",
  },
  {
    id: 17,
    image: "/assets/images/gallery/gallery-image-7.jpg",
    alt: "image",
  },
  {
    id: 18,
    image: "/assets/images/gallery/gallery-image-8.jpg",
    alt: "image",
  },
  {
    id: 19,
    image: "/assets/images/gallery/gallery-image-9.jpg",
    alt: "image",
  },
  {
    id: 20,
    image: "/assets/images/gallery/gallery-image-10.jpg",
    alt: "image",
  },
  {
    id: 21,
    image: "/assets/images/gallery/gallery-image-11.jpg",
    alt: "image",
  },
  {
    id: 22,
    image: "/assets/images/gallery/gallery-image-12.jpg",
    alt: "image",
  },
  {
    id: 23,
    image: "/assets/images/gallery/gallery-image-13.jpg",
    alt: "image",
  },
  {
    id: 24,
    image: "/assets/images/gallery/gallery-image-14.jpg",
    alt: "image",
  },
  {
    id: 25,
    image: "/assets/images/gallery/gallery-image-15.jpg",
    alt: "image",
  },
  {
    id: 26,
    image: "/assets/images/gallery/gallery-image-16.jpg",
    alt: "image",
  },
  {
    id: 27,
    image: "/assets/images/gallery/gallery-image-17.jpg",
    alt: "image",
  },
  {
    id: 28,
    image: "/assets/images/gallery/gallery-image-19.jpg",
    alt: "image",
  },
  {
    id: 29,
    image: "/assets/images/gallery/gallery-image-20.jpg",
    alt: "image",
  },
  {
    id: 29,
    image: "/assets/images/gallery/gallery-image-21.jpg",
    alt: "image",
  },
  {
    id: 29,
    image: "/assets/images/gallery/gallery-image-22.jpg",
    alt: "image",
  },
  {
    id: 29,
    image: "/assets/images/gallery/gallery-image-23.jpg",
    alt: "image",
  },
  {
    id: 30,
    image: "/assets/images/gallery/24.jpg",
    alt: "image",
  },
  {
    id: 31,
    image: "/assets/images/gallery/25.jpg",
    alt: "image",
  },
  {
    id: 32,
    image: "/assets/images/gallery/26.jpg",
    alt: "image",
  },
  {
    id: 33,
    image: "/assets/images/gallery/27.jpg",
    alt: "image",
  },
  {
    id: 34,
    image: "/assets/images/gallery/28.jpg",
    alt: "image",
  },
  {
    id: 35,
    image: "/assets/images/gallery/29.jpg",
    alt: "image",
  },
  {
    id: 36,
    image: "/assets/images/gallery/30.jpg",
    alt: "image",
  },
  {
    id: 37,
    image: "/assets/images/gallery/31.jpg",
    alt: "image",
  },
  {
    id: 38,
    image: "/assets/images/gallery/32.jpg",
    alt: "image",
  },
  {
    id: 39,
    image: "/assets/images/gallery/33.jpg",
    alt: "image",
  },
  {
    id: 40,
    image: "/assets/images/gallery/34.jpg",
    alt: "image",
  },
  {
    id: 41,
    image: "/assets/images/gallery/35.jpg",
    alt: "image",
  },
  {
    id: 42,
    image: "/assets/images/gallery/36.jpg",
    alt: "image",
  },
  {
    id: 43,
    image: "/assets/images/gallery/37.jpg",
    alt: "image",
  },
  {
    id: 44,
    image: "/assets/images/gallery/38.jpg",
    alt: "image",
  },
  {
    id: 45,
    image: "/assets/images/gallery/39.jpg",
    alt: "image",
  },
  {
    id: 46,
    image: "/assets/images/gallery/40.jpg",
    alt: "image",
  },
  {
    id: 47,
    image: "/assets/images/gallery/41.jpg",
    alt: "image",
  },
  {
    id: 48,
    image: "/assets/images/gallery/42.jpg",
    alt: "image",
  },
  {
    id: 49,
    image: "/assets/images/gallery/43.jpg",
    alt: "image",
  },
  {
    id: 50,
    image: "/assets/images/gallery/44.jpg",
    alt: "image",
  },
  {
    id: 51,
    image: "/assets/images/gallery/45.jpg",
    alt: "image",
  },
];
export default galleryImages;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();

  // Effect to add/remove 'dark' class on body
  useEffect(() => {
    if (location.pathname === "/") {
      const bodyElement = document.body;
      if (isDarkMode) {
        bodyElement.classList.add("dark");
      } else {
        bodyElement.classList.remove("dark");
      }
    }
    return () => {
      // Cleanup to ensure 'dark' class is removed when leaving the homepage
      document.body.classList.remove("dark");
    };
  }, [isDarkMode, location.pathname]);

  const handleToggle = () => {
    if (location.pathname === "/") {
      setIsDarkMode((prevMode) => !prevMode);
    }
  };

  // Render the toggle only on the homepage
  if (location.pathname !== "/") {
    return null;
  }

  return (
    <div className="dark">
      <div className="dark-button">
        <input
          type="checkbox"
          className="toggle-switch"
          checked={isDarkMode}
          onChange={handleToggle}
        />
      </div>
    </div>
  );
};

export default DarkModeToggle;

// import React, { useState, useEffect } from "react";

// const DarkModeToggle = () => {
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   // Effect to add/remove 'dark' class on body
//   useEffect(() => {
//     const bodyElement = document.body;
//     if (isDarkMode) {
//       bodyElement.classList.add("dark");
//     } else {
//       bodyElement.classList.remove("dark");
//     }
//   }, [isDarkMode]);

//   const handleToggle = () => {
//     setIsDarkMode((prevMode) => !prevMode);
//   };

//   return (
//     <div className="dark">
//       <div className="dark-button">
//         <input
//           type="checkbox"
//           className="toggle-switch"
//           checked={isDarkMode}
//           onChange={handleToggle}
//         />
//       </div>
//     </div>
//   );
// };

// export default DarkModeToggle;

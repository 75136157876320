import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import extraFaciliticsArray from "../../serviceData.js";

const RoomFacilitiesArea = () => {
  const [extraFacilitics, setExtraFacilitics] = useState([]);

  useEffect(() => {
    setExtraFacilitics(extraFaciliticsArray);
  }, []);

  // Carousel options
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <Wrapper>
      <div className="facilities-area inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="section-title two">
                <h4>Eque Heritage Hotel & Resort </h4>
                <h1>Resort’s Services</h1>
                <h1>For luxurious life</h1>
              </div>
            </div>
          </div>
          <div className="row border-add">
            <div className="col-lg-4 col-md-12">
              <div className="facilities-icon">
                <img
                  src="/assets/images/logo/Eque logo (4).png"
                  alt=""
                  width={174}
                  height={62}
                />
              </div>
              <div className="facilities-content">
                <h4>Eque Heritage Hotel & Resort</h4>
                <p>
                  Rapidiously myocardinate cross-platform are model.
                  Appropriately create interactive infrastructure. Holistically
                  facilitate luxury.
                </p>
                <Link to="/service">
                  View All Services<i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="row">
                {extraFacilitics.length > 0 && (
                  <OwlCarousel className="facilities-list" {...options}>
                    {extraFacilitics &&
                      extraFacilitics.map((facilitics) => {
                        return (
                          <div key={facilitics.id} className="item">
                            <div className="single-facilities-box">
                              <div className="facilities-thumb">
                                <img
                                  src={facilitics.image}
                                  alt="Gym Training Ground"
                                  style={{ height: "500px" }}
                                />
                              </div>
                              <div className="facilities-button">
                                <Link to={`/services-details/${facilitics.id}`}>
                                  {facilitics.title}
                                  <i className="bi bi-arrow-right-short"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .owl-carousel .owl-nav button.owl-prev,
  button.owl-next {
    display: none !important;
  }
`;

export default RoomFacilitiesArea;

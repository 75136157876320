import React from "react";
import { Link } from "react-router-dom";

const FindRoomContactArea = () => {
  return (
    <div className="contact-area style-two change-style">
      <div className="container">
        <div className="row add-backgroun">
          <div className="col-lg-6">
            <div className="section-title two">
              <h4>Contact us</h4>
              <h1>Contact With Us</h1>
              <p className="section-desc-2">
                Rapidiously myocardinate cross-platform intellectual capital
                after the model. Appropriately create interactive
                infrastructures after maintance Holisticly facilitate
                stand-alone
              </p>
            </div>
            <div className="single-contact-box">
              <div className="contact-icon">
                <i className="bi bi-telephone-fill"></i>
              </div>
              <div className="contact-title">
                <h4>Call Us Now</h4>
                <p>
                  <Link to="tel:01406767000">01406767000</Link>
                </p>
              </div>
            </div>

            {/* <div className="single-contact-box">
              <div className="contact-icon">
                <i className="bi bi-whatsapp"></i>
              </div>
              <div className="contact-title">
                <h4>Call Us on WhatsApp</h4>
                <p>
                  <Link target="_blank" to="https://wa.me/+8801738150066">
                    +8801738150066
                  </Link>
                </p>
              </div>
            </div> */}

            <div className="single-contact-box">
              <div className="contact-icon">
                <i className="bi bi-envelope"></i>
              </div>
              <div className="contact-title">
                <h4>Sent Email</h4>
                <p>
                  <Link to="mailto:equeheritageresort@gmail.com">
                    equeheritageresort@gmail.com
                  </Link>
                </p>
              </div>
            </div>
            <div className="single-contact-box">
              <div className="contact-icon">
                <i className="bi bi-geo-alt-fill"></i>
              </div>
              <div className="contact-title">
                <h4>Our Locations</h4>
                <p>
                  <Link
                    target="_blank"
                    to="https://www.google.com/maps/place/Eque+Heritage+Hotel+and+Resort,+Saidpur,+Nilphamari./@25.77336,88.902151,14z/data=!4m9!3m8!1s0x39e3517fbcb84f95:0xaf6c721b53293947!5m2!4m1!1i2!8m2!3d25.7733596!4d88.9021511!16s%2Fg%2F11h84b4_wd?hl=en&entry=ttu&g_ep=EgoyMDI1MDEwMi4wIKXMDSoASAFQAw%3D%3D"
                  >
                    Airport Road, Near By Fivestar Play Ground,
                    <br />
                    Saidpur, Nilphamari, Bangladesh
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form
              action="https://formspree.io/f/myyleorq"
              method="POST"
              id="dreamit-form"
            >
              <div className="single-contact-form">
                <div className="contact-content">
                  <h4>Get In Touch</h4>
                </div>
                <div className="single-input-box">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Your Name"
                    required=""
                  />
                </div>
                <div className="single-input-box">
                  <input
                    type="text"
                    name="Email"
                    placeholder="Enter Your Email"
                    required=""
                  />
                </div>
                <div className="single-input-box">
                  <select name="Subject" id="Subject">
                    <option value="saab">select Subject</option>
                    <option value="opel">Luxury Hotel</option>
                    <option value="audi">Room</option>
                    <option value="audi">Hottel</option>
                  </select>
                </div>
                <div className="single-input-box">
                  <textarea
                    name="Message"
                    id="Message"
                    placeholder="Write Message"
                  ></textarea>
                </div>
                <div className="single-input-box">
                  <button type="submit">Sent Message</button>
                </div>
              </div>
            </form>
            <div id="status"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindRoomContactArea;

import React from "react";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="about-area inner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-thumb">
              <img src="/assets/images/about-img/about img.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 upper">
            <div className="section-title two">
              {/* <h4>Eque Heritage Hotel & Resort</h4> */}
              <h1>Eque Heritage Hotel & Resort</h1>
              <h1 className="fs-3">Saidpur, Nilphamari</h1>
              <p className="section-desc-2" style={{ textAlign: "justify" }}>
                Welcome to Eque Heritage Hotel and Resort, your perfect place
                for peace and luxury, where beautiful nature and amazing service
                come together. Begin your day with the soft sounds of birds
                singing in the morning, creating a calm and relaxing vibe.
                Surrounded by nature’s beauty, our resort is a peaceful getaway
                from the busy world, giving you a refreshing and relaxing
                experience in a quiet and serene environment.
              </p>
            </div>
            {/* <div className="about-address"> */}
            <div className="section-desc-2">
              <p className="text-dark mb-0 mt-4">
                Airport Road, Near By Fivestar Play Ground, Saidpur-5310,
                Nilphamari, Bangladesh
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div className="luxury-button">
                <Link to="/room">Accommodation</Link>
              </div>
              <div className="luxury-button">
                <Link to="/service">Services</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;

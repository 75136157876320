import React from "react";
import BlogDetailsHeroArea from "./BlogDetailsHeroArea";
import BlogDetailsPart from "./BlogDetailsPart";

const BlogDetails = () => {
  return (
    <>
      <BlogDetailsHeroArea />
      <BlogDetailsPart />
    </>
  );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import servicesDetailsArray from "../../serviceData.js";

const ServiceDetailsArea = () => {
  const [servicesDetails, setServicesDetails] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setServicesDetails(servicesDetailsArray);
  }, []);

  const findServiceDetails =
    servicesDetails &&
    servicesDetails.find(
      (serviceDetails) => serviceDetails.id === parseInt(id)
    );

  return (
    <div className="service-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="service-details-thumb">
              <img
                src={findServiceDetails && findServiceDetails.image}
                alt=""
                height={500}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="service-time-list">
              <div className="service-time-list-content">
                <h4>Hours</h4>
              </div>
              <div className="service-time-list-item">
                <ul>
                  <li>
                    <strong>Breakfast -</strong>7.00 AM to 10.30 AM
                  </li>
                  <li>
                    <strong>Lunch -</strong>1.00 PM to 2.30 PM
                  </li>
                  <li>
                    <strong>Supper -</strong>6.00 PM to 7.00 PM
                  </li>
                  <li>
                    <strong>Dinner -</strong> 8.30 PM to 10.00 PM
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="service-details-content">
              <div className="services-details-title-box">
                <h4>Foods</h4>
                <h1>
                  {findServiceDetails && findServiceDetails.foods.heading}
                </h1>
                <p className="magnahu" style={{ textAlign: "justify" }}>
                  {findServiceDetails && findServiceDetails.description}
                </p>
              </div>
              <div className="services-details-title-box">
                <h2>Dress Code</h2>
                <p>
                  {findServiceDetails &&
                    findServiceDetails.dressCode.description}
                </p>

                <div className="service-details-item">
                  <ul>
                    {findServiceDetails &&
                      findServiceDetails.dressCode.items.map((iii, index) => {
                        return (
                          <li key={index}>
                            <i className="bi bi-check2"></i>
                            {iii}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="services-details-title-box">
                <h2>Terrace</h2>
                <p>
                  {findServiceDetails && findServiceDetails.terrace.description}
                </p>
                <div className="service-details-item">
                  <ul>
                    {findServiceDetails &&
                      findServiceDetails.terrace.items.map((itm, index) => {
                        return (
                          <li key={index}>
                            <i className="bi bi-check2"></i>
                            {itm}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsArea;

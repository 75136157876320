import React from "react";

const RoomTitleArea = () => {
  return (
    <div className="room-title-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section-title center">
              <div className="section-thumb">
                <img
                  // src="/assets/images/home-1/section-shape1.png"
                  src="/assets/images/home-1/section-shape111.png"
                  alt="Section shape"
                />
              </div>

              <h1 className="mb-0">Resort's Rooms & Suites</h1>

              <p className="section-desc-1">
                Proactively morph optimal infomediaries rather than accurate
                expertise. Intrinsicly progressive resources rather than
                resource-leveling
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomTitleArea;

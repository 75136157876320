import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "animate.css";
import featuresArray from "../../FeatureData.js";

const FeatureArea = () => {
  const [facilitates, setFacilitates] = useState([]);
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  useEffect(() => {
    setFacilitates(featuresArray);
  }, []);

  const handleMouseEnter = (id) => setHoveredFeatureId(id);
  const handleMouseLeave = () => setHoveredFeatureId(null);

  return (
    <Wrapper>
      <div className="feature-area">
        <div className="container">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center animate__animated animate__zoomIn">
                <div className="section-thumb">
                  <img
                    src="/assets/images/home-1/section-shape111.png"
                    alt="Section Shape"
                  />
                </div>
                <h1 className="mb-0">Resort's Extra Facilities</h1>
                <h1 className="fs-5 mt-0" style={{ color: "#C19D68" }}>
                  On Payment Services
                </h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsically progressive resources rather than
                  resource-leveling.
                </p>
              </div>
            </div>
          </div>

          {/* Features Grid */}
          <div className="row">
            {facilitates &&
              facilitates.map((feature) => (
                <div
                  key={feature.id}
                  className="col-lg-4 col-md-4 col-sm-6"
                  onMouseEnter={() => handleMouseEnter(feature.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={`single-feature-box ${
                      hoveredFeatureId === feature.id ? "active" : ""
                    }`}
                  >
                    <div className="feature-icon">
                      <img
                        src={feature.icon}
                        alt={feature.title}
                        style={{ width: "70px", height: "70px" }}
                      />
                    </div>

                    <div className="feature-content">
                      <h4 className="mb-4">{feature.title}</h4>
                      <p>
                        {hoveredFeatureId === feature.id
                          ? feature.description
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .single-feature-box {
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    margin: 15px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    min-height: 250px;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      background-color: #c19d68;
      color: #fff;
    }
  }

  .feature-icon img {
    transition: transform 0.3s ease-in-out;
  }

  .single-feature-box:hover .feature-icon img {
    transform: scale(1.2);
  }

  .feature-content {
    h4 {
      font-size: 25px !important;
      transition: color 0.3s ease-in-out;
    }
    p {
      font-size: 15px !important;
      line-height: 1.4;
      margin: 0;
      color: #333;
      text-align: justify;
      transition: color 0.3s ease-in-out;
    }
  }

  .single-feature-box:hover .feature-content p {
    color: #fff;
  }

  .feature-content h4::before {
    position: absolute;
    content: "";
    width: 1px;
    /* height: 30px; */
    height: 0px;
    left: 96px;
    top: -42px;
    background: rgb(53, 53, 53);
  }
`;

export default FeatureArea;

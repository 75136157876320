import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import blogDetailsArray from "../../blogData.js";

const BlogDetailsPart = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setBlogDetails(blogDetailsArray);
  }, []);

  const findBlogDetails =
    blogDetails && blogDetails.find((blogItem) => blogItem.id === parseInt(id));

  return (
    <Wrapper>
      <div className="blog-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="blog-details-thumb">
                    <img
                      src={findBlogDetails && findBlogDetails.image}
                      alt=""
                      style={{ width: "100%", height: "400px" }}
                    />
                  </div>
                  {/* <div className="blog-details-thumb">
                    <img src="/assets/images/inner/blog-details.jpg" alt="" />
                  </div> */}
                  <div className="blog-details-content">
                    <div className="meta-blog">
                      <ul>
                        <li>{findBlogDetails && findBlogDetails.date}</li>
                        {/* <li>/</li> */}
                        {/* <li>{findBlogDetails && findBlogDetails.category}</li> */}
                      </ul>
                    </div>
                    <div className="blog-details-title">
                      <Link to="/">
                        {findBlogDetails && findBlogDetails.title}
                      </Link>
                    </div>
                    <div
                      className="blog-details-desc"
                      style={{ textAlign: "justify" }}
                    >
                      <p>{findBlogDetails && findBlogDetails.description}</p>
                    </div>

                    {/* <div className="blog-details-title">
                      <h4>{findBlogDetails && findBlogDetails.rules.title}</h4>
                    </div> */}

                    {/* <div className="blog-details-desc-2">
                      <p>
                        {findBlogDetails && findBlogDetails.rules.description}
                      </p>
                    </div> */}

                    {/* <div className="blog-details-rules-item">
                      <ul>
                        {findBlogDetails &&
                          findBlogDetails.rules.items.map((ii, index) => {
                            return (
                              <li key={index}>
                                <i className="fas fa-angle-double-right"></i>
                                {ii}
                              </li>
                            );
                          })}
                      </ul>
                    </div> */}

                    {/* <div className="row">
                      {findBlogDetails &&
                        findBlogDetails.additionalThumbnails.map(
                          (imgg, index) => {
                            return (
                              <div key={index} className="col-md-6">
                                <div className="blog-details-thumb-2">
                                  <img src={imgg} alt="img" />
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div> */}

                    {/* <div className="blog-details-desc">
                      <p>
                        {findBlogDetails &&
                          findBlogDetails.additionalDescriptions}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  /* @media screen and (max-width: 425px) {
  .blog-details-thumb{
    width: 0 !important;
    height: 0 !important;
  }
} */
`;

export default BlogDetailsPart;

const brandAreaData = [
  {
    id: 1,
    img: "/assets/images/home-1/brand-1.png",
    altText: "Brand 1",
    height: 35,
  },
  {
    id: 2,
    img: "/assets/images/home-1/brand-2.png",
    altText: "Brand 2",
    height: 35,
  },
  {
    id: 3,
    img: "/assets/images/home-1/brand-3.png",
    altText: "Brand 3",
    height: 35,
  },
];

export default brandAreaData;
